import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';

import { MOBILE, TABLET, PC } from "./global";

// const StyedTableCell = styled(TableCell)({

// });

export default class SoftwareTable extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
		};
	}

	render()
	{
		const { acquisition, signalAnalysis, display, synchronizationWithOtherSW } = this.props;

		return (
			<>
				<PC>
					<TableContainer component={Paper} sx={{ maxWidth: 450 }} >
						<Table aria-label="simple table">
							<TableBody>
								{acquisition ? <TableRow key={0}> <TableCell component="th" scope="row">{"Acquisition"}</TableCell><TableCell>{acquisition}</TableCell></TableRow> : <></>}
								{signalAnalysis ? <TableRow key={0}> <TableCell component="th" scope="row">{"Signal Analysis"}</TableCell><TableCell>{signalAnalysis}</TableCell></TableRow> : <></>}
								{display ? <TableRow key={0}> <TableCell component="th" scope="row">{"Display"}</TableCell><TableCell>{display}</TableCell></TableRow> : <></>}
								{synchronizationWithOtherSW ? <TableRow key={0}> <TableCell component="th" scope="row">{"Synchronization with Other S/W"}</TableCell><TableCell>{synchronizationWithOtherSW}</TableCell></TableRow> : <></>}
							</TableBody>
						</Table>
					</TableContainer>
				</PC>
				<TABLET>
				<TableContainer component={Paper} sx={{ maxWidth: 450 }} >
						<Table aria-label="simple table">
							<TableBody>
								{acquisition ? <TableRow key={0}> <TableCell component="th" scope="row">{"Acquisition"}</TableCell><TableCell>{acquisition}</TableCell></TableRow> : <></>}
								{signalAnalysis ? <TableRow key={0}> <TableCell component="th" scope="row">{"Signal Analysis"}</TableCell><TableCell>{signalAnalysis}</TableCell></TableRow> : <></>}
								{display ? <TableRow key={0}> <TableCell component="th" scope="row">{"Display"}</TableCell><TableCell>{display}</TableCell></TableRow> : <></>}
								{synchronizationWithOtherSW ? <TableRow key={0}> <TableCell component="th" scope="row">{"Synchronization with Other S/W"}</TableCell><TableCell>{synchronizationWithOtherSW}</TableCell></TableRow> : <></>}
							</TableBody>
						</Table>
					</TableContainer>
				</TABLET>
				<MOBILE>
					<TableContainer component={Paper} sx={{ width: "100%", margin: "0px" }} >
						<Table aria-label="simple table">
							<TableBody sx={{ width: "100%" }}>
								{acquisition ? <TableRow key={0}> <TableCell component="th" scope="row">{"Acquisition"}</TableCell><TableCell>{acquisition}</TableCell></TableRow> : <></>}
								{signalAnalysis ? <TableRow key={0}> <TableCell component="th" scope="row">{"Signal Analysis"}</TableCell><TableCell>{signalAnalysis}</TableCell></TableRow> : <></>}
								{display ? <TableRow key={0}> <TableCell component="th" scope="row">{"Display"}</TableCell><TableCell>{display}</TableCell></TableRow> : <></>}
								{synchronizationWithOtherSW ? <TableRow key={0}> <TableCell component="th" scope="row">{"Synchronization with Other S/W"}</TableCell><TableCell>{synchronizationWithOtherSW}</TableCell></TableRow> : <></>}
							</TableBody>
						</Table>
					</TableContainer>
				</MOBILE>
			</>
		);
	}
};


