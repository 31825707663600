import React, { Component } from "react";

// import "../advantage.css";

import { Box, Typography, Container } from "@mui/material";
import { styled } from '@mui/material/styles';

import AOS from "aos";
import "aos/dist/aos.css";
import { MOBILE, TABLET, PC } from "./global";

const ResearchCircle = styled(Box)({
	position: "relative",
	width: "130px",
	height: "130px",
	borderRadius: "50%",
	border: "5px solid lightgray",
	backgroundColor: "#4dabcf",
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
});

const CircleTypographyBox = styled(Box)({
	position: "absolute",
});

const CircleTypography = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	color: "#ffffff",
	fontWeight: "600"
});

const CircleSubTypography = styled(Typography)({
	color: "#ffffff",
	fontSize: "16px",
	fontWeight: "600"
});

const TaResearchCircle = styled(Box)({
	position: "relative",
	width: "130px",
	height: "130px",
	borderRadius: "50%",
	border: "5px solid lightgray",
	backgroundColor: "#4dabcf",
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
});
const MoResearchCircle = styled(Box)({
	position: "relative",
	width: "100px",
	height: "100px",
	borderRadius: "50%",
	border: "5px solid lightgray",
	backgroundColor: "#4dabcf",
	display: "flex",
	justifyContent: "center",
	alignItems: "center"
});

const MoCircleTypographyBox = styled(Box)({
	position: "absolute",
});

const TaCircleTypography = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	color: "#ffffff",
	fontSize: "16px",
	fontWeight: "600"
});
const MoCircleTypography = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	color: "#ffffff",
	fontSize: "12px",
	fontWeight: "600"
});

const TaCircleSubTypography = styled(Typography)({
	color: "#ffffff",
	fontSize: "16px",
	fontWeight: "bold"
});
const MoCircleSubTypography = styled(Typography)({
	color: "#ffffff",
	fontSize: "14px",
	fontWeight: "bold"
});

export default class Advantage extends Component
{
	componentDidMount()
	{
		AOS.init();
	}

	render()
	{

		return (
			<>
				<PC>
					<Box position="relative" sx={{ height: "100vh", backgroundColor: "black" }}>
						<Container>
							<Box sx={{ paddingTop: "150px" }}>
								<Typography variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>연구 프로젝트 자문</Typography>
							</Box>
							<Box>
								<Box my={2}>
									<Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>생체 신호를 응용한 <span style={{ color: "#00a0b9" }}>제품 개발 관련 기술 연구</span> 및 관련  <span style={{ color: "#00a0b9" }}>기술 시장에 대한 Insight</span>가</Typography>
									<Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}> 필요한 경우 이에 대한 체계적인 자문 혹은 교육을 수행</Typography>
								</Box>
								<Box m={5} display="column" sx={{ width: "100%" }} >
									<Box my={2} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000">
										<ResearchCircle>
											<CircleTypographyBox>
												<Box justifyContent="column">
													<CircleTypography>| STEP 1 |</CircleTypography>
													<CircleTypography>Accept Contents</CircleTypography>
												</Box>
											</CircleTypographyBox>
										</ResearchCircle>
										<Box display="flex" justifyContent="center" alignItems="center" >
											<CircleSubTypography m={5}>상세한 정보를 포함한 요구 사항 접수</CircleSubTypography>
										</Box>
									</Box>
									<Box my={2} mr={50} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="700" data-aos-duration="1000">
										<Box display="flex" justifyContent="center" alignItems="center">
											<Box m={5} display="column">
												<CircleSubTypography >고객 요구 사항의 표면적, 내면적 사항을 모두 분석하고</CircleSubTypography>
												<CircleSubTypography > 컨설팅 방향을 도출</CircleSubTypography>
											</Box>
										</Box>
										<Box>
											<ResearchCircle>
												<CircleTypographyBox>
													<Box justifyContent="column">
														<CircleTypography >| STEP 2 |</CircleTypography>
														<CircleTypography>Analysis</CircleTypography>
														<CircleTypography>of Contents</CircleTypography>
													</Box>
												</CircleTypographyBox>
											</ResearchCircle>
										</Box>
									</Box>
									<Box my={2} ml={15} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000">
										<ResearchCircle>
											<CircleTypographyBox>
												<Box justifyContent="column">
													<CircleTypography >| STEP 3 |</CircleTypography>
													<CircleTypography>Consulting</CircleTypography>
												</Box>
											</CircleTypographyBox>
										</ResearchCircle>
										<Box m={5} display="flex" justifyContent="center" alignItems="center">
											<CircleSubTypography >분석 시트를 기반으로 고객 상담 후 맞춤형 컨설팅 진행</CircleSubTypography>
										</Box>
									</Box>
									<Box my={2} mr={50} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="900" data-aos-duration="1000">
										<Box m={5} display="flex" justifyContent="center" alignItems="center">
											<CircleSubTypography>컨설팅 용역 수행 후 추가 사항 요청시 보완</CircleSubTypography>
										</Box>
										<ResearchCircle>
											<CircleTypographyBox>
												<Box justifyContent="column">
													<CircleTypography >| STEP 4 |</CircleTypography>
													<CircleTypography>Feedback</CircleTypography>
												</Box>
											</CircleTypographyBox>
										</ResearchCircle>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box>
				</PC>
				<TABLET>
					<Box position="relative" sx={{ width: "100%", height: "100%", backgroundColor: "black" }}>
						<Container>
							<Box>
								<Typography variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>연구 프로젝트 자문</Typography>
							</Box>
							<Box my={1}>
								<Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>생체 신호를 응용한 <span style={{ color: "#00a0b9" }}>제품 개발 관련 기술 연구</span> 및 관련 <span style={{ color: "#00a0b9" }}>기술 시장에 대한 Insight</span>가 필요한 경우 이에 대한 체계적인 자문 혹은 교육을 수행</Typography>
							</Box>
							<Box paddingY={10} mb={2} display="column" sx={{ width: "100%", height: "100%" }}>
								<Box my={1} pl={10} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000">
									<TaResearchCircle>
										<MoCircleTypographyBox>
											<Box justifyContent="column">
												<TaCircleTypography>| STEP 1 |</TaCircleTypography>
												<TaCircleTypography>Accept</TaCircleTypography>
												<TaCircleTypography>Contents</TaCircleTypography>
											</Box>
										</MoCircleTypographyBox>
									</TaResearchCircle>
									<Box display="flex" justifyContent="center" alignItems="center" >
										<TaCircleSubTypography m={1}>상세한 정보를 포함한 요구 사항 접수</TaCircleSubTypography>
									</Box>
								</Box>
								<Box pr={10} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="700" data-aos-duration="1000">
									<Box display="flex" justifyContent="center" alignItems="center">
										<Box m={1} display="column">
											<TaCircleSubTypography >고객 요구 사항의 표면적, 내면적</TaCircleSubTypography>
											<TaCircleSubTypography >사항을 모두 분석하고 컨설팅 방향을 도출</TaCircleSubTypography>
										</Box>
									</Box>
									<Box>
										<TaResearchCircle>
											<MoCircleTypographyBox >
												<Box justifyContent="column">
													<TaCircleTypography >| STEP 2 |</TaCircleTypography>
													<TaCircleTypography>Analysis</TaCircleTypography>
													<TaCircleTypography>of Contents</TaCircleTypography>
												</Box>
											</MoCircleTypographyBox>
										</TaResearchCircle>
									</Box>
								</Box>
								<Box pl={10} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000">
									<TaResearchCircle>
										<MoCircleTypographyBox>
											<Box justifyContent="column">
												<TaCircleTypography>| STEP 3 |</TaCircleTypography>
												<TaCircleTypography>Consulting</TaCircleTypography>
											</Box>
										</MoCircleTypographyBox>
									</TaResearchCircle>
									<Box m={3} display="column">
										<TaCircleSubTypography >분석 시트를 기반으로 고객 상담 후 </TaCircleSubTypography>
										<TaCircleSubTypography >맞춤형 컨설팅 진행</TaCircleSubTypography>
									</Box>
								</Box>
								<Box pr={10} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="900" data-aos-duration="1000">
									<Box display="flex" justifyContent="center" alignItems="center">
										<TaCircleSubTypography m={1}>컨설팅 용역 수행 후 추가 사항 요청시 보완</TaCircleSubTypography>
									</Box>
									<TaResearchCircle>
										<MoCircleTypographyBox>
											<Box justifyContent="column">
												<TaCircleTypography >| STEP 4 |</TaCircleTypography>
												<TaCircleTypography>Feedback</TaCircleTypography>
											</Box>
										</MoCircleTypographyBox>
									</TaResearchCircle>
								</Box>
							</Box>
						</Container>
					</Box>
				</TABLET>
				<MOBILE>
					<Box position="relative" sx={{ width: "100vw", height: "100%", backgroundColor: "black" }}>
						<Container>
							<Box sx={{ paddingTop: "100px" }}>
								<Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>연구 프로젝트 자문</Typography>
							</Box>
							<Box my={1}>
								<Typography variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }}>생체 신호를 응용한 <span style={{ color: "#00a0b9" }}>제품 개발 관련 기술 연구</span> 및 관련 <span style={{ color: "#00a0b9" }}>기술 시장에 대한 Insight</span>가 필요한 경우 이에 대한 체계적인 자문 혹은 교육을 수행</Typography>

							</Box>
							<Box mt={2} display="column" sx={{ width: "100%" }}>
								<Box my={2} display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000">
									<MoResearchCircle>
										<MoCircleTypographyBox>
											<Box justifyContent="column">
												<MoCircleTypography>| STEP 1 |</MoCircleTypography>
												<MoCircleTypography>Accept</MoCircleTypography>
												<MoCircleTypography>Contents</MoCircleTypography>
											</Box>
										</MoCircleTypographyBox>
									</MoResearchCircle>
									<Box display="flex" justifyContent="center" alignItems="center" >
										<MoCircleSubTypography m={2}>상세한 정보를 포함한 요구 사항 접수</MoCircleSubTypography>
									</Box>
								</Box>
								<Box display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="700" data-aos-duration="1000">
									<Box display="flex" justifyContent="center" alignItems="center">
										<Box m={2} display="column">
											<MoCircleSubTypography >고객 요구 사항의 표면적, 내면적</MoCircleSubTypography>
											<MoCircleSubTypography >사항을 모두 분석하고 컨설팅 방향을 도출</MoCircleSubTypography>
										</Box>
									</Box>
									<Box>
										<MoResearchCircle>
											<MoCircleTypographyBox >
												<Box justifyContent="column">
													<MoCircleTypography >| STEP 2 |</MoCircleTypography>
													<MoCircleTypography>Analysis</MoCircleTypography>
													<MoCircleTypography>of Contents</MoCircleTypography>
												</Box>
											</MoCircleTypographyBox>
										</MoResearchCircle>
									</Box>
								</Box>
								<Box display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000">
									<MoResearchCircle>
										<MoCircleTypographyBox>
											<Box justifyContent="column">
												<MoCircleTypography>| STEP 3 |</MoCircleTypography>
												<MoCircleTypography>Consulting</MoCircleTypography>
											</Box>
										</MoCircleTypographyBox>
									</MoResearchCircle>
									<Box m={2} display="column">
										<MoCircleSubTypography >분석 시트를 기반으로 고객 상담 후 </MoCircleSubTypography>
										<MoCircleSubTypography >맞춤형 컨설팅 진행</MoCircleSubTypography>
									</Box>
								</Box>
								<Box display="flex" justifyContent="center" data-aos="zoom-in" data-aos-delay="700" data-aos-duration="1000">
									<Box display="flex" justifyContent="center" alignItems="center">
										<Box m={2} display="column">
											<MoCircleSubTypography >컨설팅 용역 수행 후 추가 사항 요청시 보완</MoCircleSubTypography>
										</Box>
									</Box>
									<Box>
										<MoResearchCircle>
											<MoCircleTypographyBox >
												<Box justifyContent="column">
													<MoCircleTypography >| STEP 4 |</MoCircleTypography>
													<MoCircleTypography>Feedback</MoCircleTypography>
												</Box>
											</MoCircleTypographyBox>
										</MoResearchCircle>
									</Box>
								</Box>
							</Box>
						</Container>
					</Box>
				</MOBILE>
			</>
		);
	}
}

