import { Component } from 'react';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';

import './App.css';

import { Box } from '@mui/material';

import MainPage from "./components/mainpage";
import Layout from "./components/layout";
import CompanyPage from "./components/companypage";
import ProductPage from "./components/productpage";
import BusinessPage from './components/businesspage';
import ResearchPage from './components/researchpage';
import HwPage from "./components/hwpage";
import ProductDetailsWarp from './components/productdetails';
import DataProductDetailsWarp from './components/dataproductdetails';
import BusinessList from './components/businesslist';
import BioscanPage from "./components/bioscanpage";
import Ai from "./components/ai";
import ScrollToTop from './components/scrolltotop';
import FloatingActionButtons from './components/FloatingActionButtons';


class App extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            // 리디렉션 조건을 체크하기 위한 상태
            // 예시: shouldRedirect: false
        };
    }

    render()
    {
        return (
            <BrowserRouter>
                <Box position="relative">
                    <FloatingActionButtons />
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<Layout />} >
                            <Route index element={<MainPage />} />
                            <Route path="/companypage" element={<CompanyPage />} />
                            <Route path="/productpage" element={<ProductPage />} />
                            <Route path="/hwpage" element={<HwPage />} />
                            <Route path="/businesspage" element={<BusinessPage />} />
                            <Route path="/researchpage" element={<ResearchPage />} />
                            <Route path="/productdetails" element={<ProductDetailsWarp />} />
                            <Route path="/dataproductdetails" element={<DataProductDetailsWarp />} />
                            <Route path="/businesslist" element={<BusinessList />} />
                            <Route path="/ai" element={<Ai />} />
                            <Route path="/bioscanpage" element={<BioscanPage />} />
                        </Route>
                    </Routes>
                </Box>
            </BrowserRouter>
        )
    }
}

export default App;