import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { PC, TABLET, MOBILE } from './global';
const handleDownloadPdf = () =>
{
	// 프로젝트 폴더 내에 있는 BioBrain_Catalog.pdf 파일 경로를 지정
	const pdfFilePath = process.env.PUBLIC_URL + "/BioBrain_Catalog.pdf";

	// 다운로드 링크를 생성하고 클릭
	const a = document.createElement("a");
	a.href = pdfFilePath;
	a.download = "BioBrain_Catalog.pdf"; // 다운로드할 파일의 이름을 설정
	a.click();
};

export default function FloatingActionButtons()
{
	return (
		<>
			<PC>
				<Box sx={{ '& > :not(style)': { m: 2 }, backgroundColor: "rgba(22, 22, 22, 0)", position: "fixed", right: "5px", bottom: "5px", zIndex: 9999 }}>
					<Fab

						variant="extended"
						sx={{
							backgroundColor: "#EF9F5B",
							// backgroundColor: "white",
							// color: "black",
							'&:hover': {
								backgroundColor: "rgba(216, 113, 55, 0.8)",
								boxShadow: 'inset 0 0px 10px 0px rgba(255, 255, 255, 0.14), inset 0px 5px 10px 0px rgba(255, 255, 255, 0.11), inset 0px 2px 5px 0px rgba(255, 255, 255, 0.4), inset 0px 3px 20px 0px rgba(0, 0, 0, 0.25)',
							},
							boxShadow: 'inset 0 0px 10px 0px rgba(255, 255, 255, 0.14), inset 0px 5px 10px 0px rgba(255, 255, 255, 0.11), inset 0px 2px 5px 0px rgba(255, 255, 255, 0.4), inset 0px 3px 20px 0px rgba(0, 0, 0, 0.25)',
						}}
						onClick={handleDownloadPdf}
					>
						<Box display="flex" alignItems="center" sx={{ textTransform: "none" }}>
							<DownloadIcon sx={{ color: "white", mr: 1 }} />
							<Typography variant="body2" sx={{ color: "white" }}>Catalog</Typography>
						</Box>
					</Fab>
				</Box>
			</PC>
			<TABLET>
				<Box sx={{ '& > :not(style)': {}, backgroundColor: "rgba(22, 22, 22, 0)", position: "fixed", right: "25px", bottom: "20px", zIndex: 9999 }}>
					<Fab
						variant="extended"
						color='rgb(243, 207, 152)'
						sx={{
							backgroundColor: "#EF9F5B",
							color: "white",
							'&:hover': {
								backgroundColor: "rgba(216, 113, 55, 0.8)",
								boxShadow: 'inset 0 0px 10px 0px rgba(255, 255, 255, 0.14), inset 0px 5px 10px 0px rgba(255, 255, 255, 0.11), inset 0px 2px 5px 0px rgba(255, 255, 255, 0.4), inset 0px 3px 20px 0px rgba(0, 0, 0, 0.25)',
							},
							boxShadow: 'inset 0 0px 10px 0px rgba(255, 255, 255, 0.14), inset 0px 5px 10px 0px rgba(255, 255, 255, 0.11), inset 0px 2px 5px 0px rgba(255, 255, 255, 0.4), inset 0px 3px 20px 0px rgba(0, 0, 0, 0.25)',
						}}
						onClick={handleDownloadPdf}
					>
						<Box display="flex" alignItems="center" sx={{ textTransform: "none" }}>
							<DownloadIcon sx={{ color: "white", mr: 1 }} />
							<Typography variant="body2" sx={{ color: "white" }}>Catalog</Typography>
						</Box>
					</Fab>
				</Box>
			</TABLET>
			<MOBILE>
				<Box sx={{ '& > :not(style)': { m: 1 }, backgroundColor: "rgba(22, 22, 22, 0)", position: "fixed", right: "5px", bottom: "20px", zIndex: 9999 }}>
					<Fab
						variant="extended"
						color='#FDCB9E'
						sx={{
							backgroundColor: "#EF9F5B",
							color: "white",
							'&:hover': {
								backgroundColor: "rgba(216, 113, 55, 0.8)",
								boxShadow: 'inset 0 0px 10px 0px rgba(255, 255, 255, 0.14), inset 0px 5px 10px 0px rgba(255, 255, 255, 0.11), inset 0px 2px 5px 0px rgba(255, 255, 255, 0.4), inset 0px 3px 20px 0px rgba(0, 0, 0, 0.25)',
							},
							boxShadow: 'inset 0 0px 10px 0px rgba(255, 255, 255, 0.14), inset 0px 5px 10px 0px rgba(255, 255, 255, 0.11), inset 0px 2px 5px 0px rgba(255, 255, 255, 0.4), inset 0px 3px 20px 0px rgba(0, 0, 0, 0.25)',
						}}
						onClick={handleDownloadPdf}
					>
						<Box display="flex" alignItems="center" sx={{ textTransform: "none" }}>
							<DownloadIcon sx={{ color: "white", mr: 1 }} />
							<Typography variant="body2" sx={{ color: "white" }}>Catalog</Typography>
						</Box>
					</Fab>
				</Box>
			</MOBILE>
		</>
	);
};