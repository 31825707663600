import React, { Component } from "react";

import { styled } from '@mui/material/styles';
// import { keyframes } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';

import AOS from "aos";
import "aos/dist/aos.css";
import SwiperY from "./swipery";

import { MOBILE, TABLET, PC } from "./global";

import Researchconsulting from "./researchconsulting";
import ResearchTabs from "./researchtabs";
import Footer from "./footer";

const ResearchTopBox = styled(Box)({

	width: "250px",
	height: "250px",
	margin: "50px",
	borderRadius: "40px 10px"
});

const ResearchTopTypography = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	color: "white",
	fontSize: "18px",
	fontWeight: "bold",
});

const TaResearchTopBox = styled(Box)({

	width: "200px",
	height: "200px",
	// margin: "50px",
	borderRadius: "40px 10px"
});
const MoResearchTopBox = styled(Box)({

	width: "120px",
	height: "120px",
	// margin: "50px",
	borderRadius: "40px 10px"
});

const MoResearchTopTypography = styled(Typography)({
	display: "flex",
	justifyContent: "center",
	color: "white",
	fontSize: "14px",
	fontWeight: "bold",
	marginTop: 10
});

export default class ResearchPage extends Component
{
	constructor(props)
	{
		super(props);

		this.state = { fadeCompany: false, fadeBusinees: false, fadeResearch: false };
	}

	componentDidMount()
	{
		AOS.init();
	}

	render()
	{
		return (
			<>
				<PC>
					<Box height="100vh" sx={{ backgroundColor: "black" }}>
						<SwiperY>
							<Box p={20} sx={{ height: "100vh", backgroundColor: "black" }}>
								<Container>
									<Box>
										<Typography my={2} variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>핵심기술</Typography>
										<Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}><span style={{ color: "#00a0b9" }}>생체신호계측</span> 기술 및 <span style={{ color: "#00a0b9" }}>AI</span>기술을 기반으로 성장하는 기업</Typography>
									</Box>
									<Box display="flex" justifyContent="center" mt={10}>
										<Box data-aos="zoom-in-up" data-aos-delay="600" data-aos-duration="1000">
											<ResearchTopBox sx={{ backgroundColor: "#69c5d8" }}>
												<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
													<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_1.png'} ></Box>
												</Box>
											</ResearchTopBox>
											<ResearchTopTypography>맞춤형 데이터 가공기술 보유</ResearchTopTypography>
										</Box>
										<Box data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="1000">
											<ResearchTopBox sx={{ backgroundColor: "#5289c8" }}>
												<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
													<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_2.png'} ></Box>
												</Box>
											</ResearchTopBox>
											<ResearchTopTypography>다양한 S/W 및 H/W</ResearchTopTypography>
											<ResearchTopTypography>개발기술 및 노하우 보유</ResearchTopTypography>
										</Box>
										<Box data-aos="zoom-in-up" data-aos-delay="800" data-aos-duration="1000">
											<ResearchTopBox sx={{ backgroundColor: "#cee9f2" }}>
												<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
													<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_3.png'} ></Box>
												</Box>
											</ResearchTopBox>
											<ResearchTopTypography>영상, 음성인식 AI 기술보유</ResearchTopTypography>
										</Box>
									</Box>
								</Container>
							</Box>
							<Box sx={{ height: "100vh", backgroundColor: "black" }}>
								<Container sx={{ paddingTop: "150px" }}>
									<Box>
										<Typography variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>특허 및 산업재산권</Typography>
									</Box>
									<Box display="flex" justifyContent="center" my={5} data-aos="zoom-in-up" data-aos-delay="600" data-aos-duration="1000">
										<Box>
											<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
												<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/certificate5.png'} ></Box>
											</Box>
										</Box>
									</Box>
									<ResearchTabs />
								</Container>
							</Box>
							<Box sx={{ backgroundColor: "black" }}>
								<Researchconsulting />
							</Box>
							<Footer />
						</SwiperY>
					</Box >
				</PC>
				<TABLET>
					<Box sx={{ width: "100%",  backgroundColor: "black" }}>
						<Box sx={{ height:"100%", backgroundColor: "black" }}>
							<Container sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingTop: "200px" }}>
								<Box sx={{ width: "100%", backgroundColor: "black" }} >
									<Typography m={1} variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>핵심기술</Typography>
									<Typography m={1} variant="h6" sx={{ width: "100%", color: "#ffffff", fontWeight: "bold" }}><span style={{ color: "#00a0b9", fontWeight: "bold" }}>생체신호계측</span> 기술 및 <span style={{ color: "#00a0b9", fontWeight: "bold" }}>AI</span>기술을 기반으로 성장하는 기업</Typography>
								</Box>
								<Box paddingY={10} sx={{ width:"100%", display:"flex", justifyContent:"center", marginBottom: "50px" }}>
									<Box m={2} data-aos="zoom-in-up" data-aos-delay="600" data-aos-duration="800">
										<Box display="flex" justifyContent="center" >
											<TaResearchTopBox sx={{ backgroundColor: "#69c5d8" }}>
												<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_1.png'} />
											</TaResearchTopBox>
										</Box>
										<MoResearchTopTypography>맞춤형 데이터 가공기술 보유</MoResearchTopTypography>
									</Box>
									<Box m={2} justifyContent="column" data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="800">
										<Box display="flex" justifyContent="center" >
											<TaResearchTopBox sx={{ backgroundColor: "#5289c8" }}>
												<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_2.png'} />
											</TaResearchTopBox>
										</Box>
										<MoResearchTopTypography >다양한 S/W 및 H/W</MoResearchTopTypography>
										<MoResearchTopTypography sx={{ margin:"0px"}}> 개발기술 및 노하우 보유</MoResearchTopTypography>
									</Box>
									<Box m={2} justifyContent="column" data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="800">
										<Box display="flex" justifyContent="center">
											<TaResearchTopBox sx={{ backgroundColor: "#cee9f2" }}>
												<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_3.png'} />
											</TaResearchTopBox>
										</Box>
										<MoResearchTopTypography >영상, 음성인식 AI 기술보유</MoResearchTopTypography>
									</Box>
								</Box>
							</Container>
						</Box>
						<Researchconsulting />
						<Footer />
					</Box>
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black" }}>
						<SwiperY>
							<Box sx={{ height: "100%", backgroundColor: "black" }}>
								<Container sx={{ width: "100%", height: "100%",  backgroundColor: "black", paddingTop: "100px" }}>
									<Box sx={{ width: "100%", backgroundColor: "black" }} >
										<Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>핵심기술</Typography>
										<Typography variant="body2" sx={{ width: "100%", color: "#ffffff", fontWeight: "bold" }}><span style={{ color: "#00a0b9", fontWeight: "bold" }}>생체신호계측</span> 기술 및 <span style={{ color: "#00a0b9", fontWeight: "bold" }}>AI</span>기술을 기반으로 성장하는 기업</Typography>
									</Box>
									<Box mt={2} sx={{ height: "100%", justifyContent: "column", marginBottom: "50px" }}>
										<Box m={2} justifyContent="column" data-aos="zoom-in-up" data-aos-delay="600" data-aos-duration="1000">
											<Box display="flex" justifyContent="center" >
												<MoResearchTopBox sx={{ backgroundColor: "#69c5d8" }}>
													<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_1.png'} />
												</MoResearchTopBox>
											</Box>
											<MoResearchTopTypography>맞춤형 데이터 가공기술 보유</MoResearchTopTypography>
										</Box>
										<Box m={2} justifyContent="column" data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="1000">
											<Box display="flex" justifyContent="center" >
												<MoResearchTopBox sx={{ backgroundColor: "#5289c8" }}>
													<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_2.png'} />
												</MoResearchTopBox>
											</Box>
											<MoResearchTopTypography>다양한 S/W 및 H/W</MoResearchTopTypography>
											<MoResearchTopTypography  sx={{ margin:"0px"}}>개발기술 및 노하우 보유</MoResearchTopTypography>
										</Box>
										<Box m={2} justifyContent="column" data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="1000">
											<Box display="flex" justifyContent="center">
												<MoResearchTopBox sx={{ backgroundColor: "#cee9f2" }}>
													<Box component="img" sx={{ width: "100%", height: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/sec3_3.png'} />
												</MoResearchTopBox>
											</Box>
											<MoResearchTopTypography>영상, 음성인식 AI 기술보유</MoResearchTopTypography>
										</Box>
									</Box>
								</Container>
							</Box>
							<Researchconsulting />
							<Footer />
						</SwiperY >
					</Box>
				</MOBILE>
			</>
		);
	}
};