import React, { Component } from "react";

import { styled } from '@mui/material/styles';
// import { keyframes } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';

import AOS from "aos";
import "aos/dist/aos.css";
import SwiperY from "./swipery";

import BusinessList from "./businesslist";
import Footer from "./footer";
import { MOBILE, TABLET, PC } from "./global";

// const HeaderTextBox = styled(Box)({
// 	position: "absolute",
// 	top: "45%",
// 	right: "70%",
// 	transform: "translate( 20%, 30%)",
// 	textalign: "flex-start"
// });

const BoxWrap = styled(Box)({
	display: "flex",
	justifyContent: "center",
	margin: "40px",

});

const BusinessImgBox = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: "30px 5px",
	width: '300px',
	height: '300px',
	margin: "20px",
});

const ImgTitleTypography = styled(Typography)({
	display: "flex",
	justifyContent: "flex-start",
	marginTop: "50px",
	marginLeft: "25px",
	fontSize: " 20px",
	fontWeight: "600",
	color: "#fff"
});

const ImgSubTypography = styled(Typography)({
	display: "flex",
	justifyContent: "flex-start",
	marginLeft: "25px",
	fontSize: " 14px",
	fontWeight: "700",
	color: "#fff",
	width: "290px",
	marginTop: "10px"
});

const MoBoxWrap = styled(Box)({
	justifyContent: "column",
});

const MoBusinessImgBox = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	borderRadius: "30px 5px",
	width: '100px',
	height: '100px',
});

const MoImgTitleTypography = styled(Typography)({
	display: "flex",
	justifyContent: "flex-start",
	marginTop: "5px",
	marginLeft: "15px",
	fontSize: " 18px",
	fontWeight: "800",
	color: "#fff"
});

const MoImgSubTypography = styled(Typography)({
	display: "flex",
	justifyContent: "flex-start",
	fontSize: " 12px",
	fontWeight: "500",
	color: "#fff",
	width: "250px",
	margin: "10px"
});

export default class BusinessPage extends Component
{
	constructor(props)
	{
		super(props);

		this.state = { fadeCompany: false, fadeBusinees: false, fadeResearch: false };
	}

	componentDidMount()
	{
		AOS.init();
	}

	render()
	{
		return (
			<>
				<PC>
					<Box sx={{ backgroundColor: "black" }}>
						<SwiperY>
							<Box sx={{ backgroundColor: "black" }}>
								<Container sx={{ width: "100%", height: "100vh", backgroundColor: "black", paddingTop: "150px" }}>
									<Box sx={{ backgroundColor: "black" }}>
										<Typography data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>We <span style={{ color: "#00a0b9" }}>Dream</span> Your <span style={{ color: "#00a0b9" }}>Dream</span>!</Typography>
										<Typography data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 생체계측과 AI기술을 이용하여 다양한 분야를 개척해왔습니다.
										</Typography>
									</Box>
									<BoxWrap>
										<Box m={5}>
											<BusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/joshua-chehov-ZSo4axN3ZXI-unsplash.jpg'}></BusinessImgBox>
											<Box>
												<ImgTitleTypography>생체계측분야</ImgTitleTypography>
												<ImgSubTypography>생체계측 분야에서 다양한 제품과 솔루션을 개발하고 제공하고 있습니다. 최신 기술을 접목하여 정확하고 효율적인 분석이 가능하며, 국내외에서 인정받는 높은 기술력을 보유하고 있습니다.</ImgSubTypography>
											</Box>
										</Box>
										<Box m={5}>
											<BusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/laptop.jpg'}></BusinessImgBox>
											<Box>
												<ImgTitleTypography>AI분야</ImgTitleTypography>
												<ImgSubTypography>AI 기술을 활용하여 고객 데이터 분석 및 예측 분석 제공, 이미지 및 비디오, 음성 인식 기술을 활용한 다양한 맞춤 추천 서비스 제공, 인공지능 기반의 자동화 솔루션 제공, 클라우드 기반의 인공지능 플랫폼 구축 및 관리 지원.</ImgSubTypography>
											</Box>
										</Box>
										<Box m={5}>
											<BusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/engineer.jpg'}></BusinessImgBox>
											<Box>
												<ImgTitleTypography>연구지원 및 컨설팅분야</ImgTitleTypography>
												<ImgSubTypography>고객사의 기술 전략 수립과 R&D 지원, 기술 컨설팅, 창업 지원, 기술 이전 지원, 국내외 연구 기관과 산업체 간 연구 협력 촉진을 지원합니다.</ImgSubTypography>
											</Box>
										</Box>
									</BoxWrap>
								</Container>
							</Box>
							<Box sx={{ height: "100%", backgroundColor: "black", marginBottom: "100px" }}>
								<BusinessList />
							</Box>
							<Footer />
						</SwiperY>
					</Box >
				</PC >
				<TABLET>
					<Box sx={{backgroundColor: "black" }}>
						<Box sx={{ height: "100%", backgroundColor: "black" }}>
							<Container sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingTop: "120px" }}>
								<Box paddingY={10} sx={{ backgroundColor: "black" }}>
									<Typography data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>We <span style={{ color: "#00a0b9" }}>Dream</span> Your <span style={{ color: "#00a0b9" }}>Dream</span>!</Typography>
									<Typography data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 생체계측과 AI기술 등 다양한 분야를 개척해왔습니다.
									</Typography>
								</Box>
								<MoBoxWrap>
									<Box m={2} display="flex" justifyContent="center">
										<MoBusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/joshua-chehov-ZSo4axN3ZXI-unsplash.jpg'}></MoBusinessImgBox>
										<Box>
											<MoImgTitleTypography>생체계측분야</MoImgTitleTypography>
											<MoImgSubTypography>생체계측 분야에서 다양한 제품과 솔루션을 개발하고 제공하고 있습니다. 최신 기술을 접목하여 정확하고 효율적인 분석이 가능하며, 국내외에서 인정받는 높은 기술력을 보유하고 있습니다.</MoImgSubTypography>
										</Box>
									</Box>
									<Box m={3} display="flex" justifyContent="center">
										<MoBusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/laptop.jpg'}></MoBusinessImgBox>
										<Box>
											<MoImgTitleTypography>AI분야</MoImgTitleTypography>
											<MoImgSubTypography>AI 기술을 활용하여 고객 데이터 분석 및 예측 분석 제공, 이미지 및 비디오, 음성 인식 기술을 활용한 다양한 맞춤 추천 서비스 제공, 인공지능 기반의 자동화 솔루션 제공, 클라우드 기반의 인공지능 플랫폼 구축 및 관리 지원.</MoImgSubTypography>
										</Box>
									</Box>
									<Box m={3} display="flex" justifyContent="center">
										<MoBusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/engineer.jpg'}></MoBusinessImgBox>
										<Box>
											<MoImgTitleTypography>연구지원 및 컨설팅분야</MoImgTitleTypography>
											<MoImgSubTypography>고객사의 기술 전략 수립과 R&D 지원, 기술 컨설팅, 창업 지원, 기술 이전 지원, 국내외 연구 기관과 산업체 간 연구 협력 촉진을 지원합니다.</MoImgSubTypography>
										</Box>
									</Box>
								</MoBoxWrap>
							</Container>
						</Box>
						<Box pr={5} sx={{ height: "100%"}}>
							<BusinessList />
						</Box>
						<Footer />
					</Box >
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100%", backgroundColor: "black" }}>
						<SwiperY>
							<Box sx={{ backgroundColor: "black" }}>
								<Container sx={{ width: "100%", height: "100vh", backgroundColor: "black", paddingTop: "120px" }}>
									<Box mb={3} sx={{ backgroundColor: "black" }}>
										<Typography data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>We <span style={{ color: "#00a0b9" }}>Dream</span> Your <span style={{ color: "#00a0b9" }}>Dream</span>!</Typography>
										<Typography data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" variant="body2" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오브레인은 생체계측과 AI기술을 이용하여 다양한 분야를 개척해왔습니다.
										</Typography>
									</Box>
									<MoBoxWrap>
										<Box m={2} display="flex" justifyContent="center">
											<MoBusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/joshua-chehov-ZSo4axN3ZXI-unsplash.jpg'}></MoBusinessImgBox>
											<Box>
												<MoImgTitleTypography>생체계측분야</MoImgTitleTypography>
												<MoImgSubTypography>생체계측 분야에서 다양한 제품과 솔루션을 개발하고 제공하고 있습니다. 최신 기술을 접목하여 정확하고 효율적인 분석이 가능하며, 국내외에서 인정받는 높은 기술력을 보유하고 있습니다.</MoImgSubTypography>
											</Box>
										</Box>
										<Box m={3} display="flex" justifyContent="center">
											<MoBusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/laptop.jpg'}></MoBusinessImgBox>
											<Box>
												<MoImgTitleTypography>AI분야</MoImgTitleTypography>
												<MoImgSubTypography>AI 기술을 활용하여 고객 데이터 분석 및 예측 분석 제공, 이미지 및 비디오, 음성 인식 기술을 활용한 다양한 맞춤 추천 서비스 제공, 인공지능 기반의 자동화 솔루션 제공, 클라우드 기반의 인공지능 플랫폼 구축 및 관리 지원.</MoImgSubTypography>
											</Box>
										</Box>
										<Box m={3} display="flex" justifyContent="center">
											<MoBusinessImgBox component="img" src={process.env.PUBLIC_URL + '/assets/images/engineer.jpg'}></MoBusinessImgBox>
											<Box>
												<MoImgTitleTypography>연구지원 및 컨설팅분야</MoImgTitleTypography>
												<MoImgSubTypography>고객사의 기술 전략 수립과 R&D 지원, 기술 컨설팅, 창업 지원, 기술 이전 지원, 국내외 연구 기관과 산업체 간 연구 협력 촉진을 지원합니다.</MoImgSubTypography>
											</Box>
										</Box>
									</MoBoxWrap>
								</Container>
							</Box>
							<Box >
								<BusinessList />
							</Box>
							<Footer />
						</SwiperY>
					</Box >
				</MOBILE>
			</>
		);
	}
}

