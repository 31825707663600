import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import styledc from 'styled-components';

import AOS from "aos";
import "aos/dist/aos.css";
// import SwiperY from "./swipery";
import { MOBILE, TABLET, PC, DEFAULT_DELAY } from "./global";

import Footer from "./footer";

const BoxWrap = styledc.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

const MoBoxWrap = styledc.div`
width: 100%;
height: "100%;
display: flex;
align-items: center;
justify-content: center;
`;

const StyledGrid = styled(Grid)`
&& {
    margin: 0 auto;
    max-width: 950px;
	padding: 0px;
}`;

const SubBox = styledc.div`
position: relative;
background: #000;
width: 100%;
height: auto;
padding-bottom: 66.67%;
cursor: pointer;
overflow: hidden;
@media screen and (max-width: 1000px) {
	width: 100%;
}
`;
// border-radius: 30px 5px;

const ImageButton = styledc.img`
width: 100%;
height: 100%;
object-fit: cover;
transition: all 0.3s ease-in-out;
`;

const DataImageButton = styledc.img`
width: 100%;
height: 100%;
object-fit: fill;
transition: all 0.3s ease-in-out;
`;

const Info = styledc.div`
position: absolute;
left: 0;
bottom: 0;
color: #fff;
width: 100%;
padding: 15px;
box-sizing: border-box;
opacity: 0;
transition: all 0.3s ease-in-out;
`;

const InfoTitle = styledc.h3`
font-size: 40px;
line-height: 30px;
margin: 0px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const MoInfoTitle = styledc.h3`
font-size: 20px;
line-height: 30px;
margin: 0px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
`;

const BoxBefore = styledc.div`
content: "";
background: #fff;
width: 0px;
height: 1px;
position: absolute;
right: 20px;
top: 20px;
transition: all 0.35s ease;
`;

const BoxAfter = styledc.div`
content: "";
background: #fff;
width: 1px;
height: 0px;
position: absolute;
right: 20px;
top: 20px;
transition: all 0.35s ease;
`;

const ImageButtonWrap = styledc.div`
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
&:hover {
    ${ImageButton} {
    opacity: 0.2;
    }
    ${BoxBefore} {
    width: 60px;
    }
    ${BoxAfter} {
    height: 60px;
    }
    ${Info} {
    opacity: 1;
    }
}
`;

export default class ProductList extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
		};
	}

	componentDidMount()
	{
		AOS.init();
	}

	render()
	{
		const products = [
			{
				title: "BIOS-mini",
				image: process.env.PUBLIC_URL + "/assets/images/minid1.png",
				delay: 500,
			},
			{
				title: "BIOS-S8",
				image: process.env.PUBLIC_URL + "/assets/images/S8d1.png",
				delay: 600,
			},
			{
				title: "BIOS-S24",
				image: process.env.PUBLIC_URL + "/assets/images/S24d1.png",
				delay: 700,
			},
			{
				title: "BIOS-S40",
				image: process.env.PUBLIC_URL + "/assets/images/S40d1.png",
				delay: 500,
			},
			{
				title: "BIOS-ST",
				image: process.env.PUBLIC_URL + "/assets/images//STd11.png",
				delay: 600,
			},
			{
				title: "BIOS-micro",
				image: process.env.PUBLIC_URL + "/assets/images/bios_micro.png",
				delay: 700,
			},
			{
				title: "BIOS-S3",
				image: process.env.PUBLIC_URL + "/assets/images/bios_s3.png",
				delay: 500,
			},
			{
				title: "BIOS-WS8",
				image: process.env.PUBLIC_URL + "/assets/images/bios1_ws8.png",
				delay: 600,
			},
			{
				title: "BIOFIT",
				image: process.env.PUBLIC_URL + "/assets/images/biofit1.png",
				delay: 700,
			},
		];

		const dataProducts = [
			{
				title: "DT-BI",
				image: process.env.PUBLIC_URL + "/assets/images/DT-BI.png",
				delay: 600,
			},
			{
				title: "DT-TS",
				image: process.env.PUBLIC_URL + "/assets/images/DT-TS.png",
				delay: 600,
			},
			{
				title: "DT-TX",
				image: process.env.PUBLIC_URL + "/assets/images/DT-TX.png",
				delay: 700,
			},
			{
				title: "DT-VD",
				image: process.env.PUBLIC_URL + "/assets/images/DT-VD.png",
				delay: 500,
			},

		];

		return (
			<>
				<PC>
					<Box sx={{ height: "100%", backgroundColor: "black", paddingBottom: "100px" }}>
						{/* <SwiperY> */}
						<Box sx={{ width: "100%", height: "100%", paddingBottom: "100px", backgroundColor: "black", paddingTop: "100px" }}>
							<Box m={3} display="flex" justifyContent="center">
								<Typography data-aos="fade-up" data-aos-delay="500" variant="h2" sx={{ color: "#ffffff", fontWeight: "bold" }}>Product</Typography>
							</Box>
							<Box mt={10} mb={10} width="100%" height="100%" display="flex" flexDirection="column">
								<Box marginY={3} display="flex" justifyContent="center">
									<Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>데이터 수집용 장비 하드웨어</Typography>
								</Box>
								<StyledGrid container spacing={2} pb={10} >
									{
										products.map((e, i) =>
										{
											const delay = DEFAULT_DELAY + (i * 50);

											return (
												<Grid item xs={12} sm={6} md={4} sx={{ backgroundColor: "black" }}>
													<BoxWrap sx={{ backgroundColor: "black" }}>
														<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
															<ImageButtonWrap >
																<Link to="/productdetails" state={{ selectedId: i }}>
																	<ImageButton
																		src={e.image} alt="상품 이름"
																	/>
																	<BoxBefore />
																	<BoxAfter />
																	<Info>
																		<InfoTitle>{e.title}</InfoTitle>
																	</Info>
																</Link>
															</ImageButtonWrap>
														</SubBox>
													</BoxWrap>
												</Grid>);
										})
									}
								</StyledGrid>
								<Box height="100%" paddingTop="100px" >
									<Box marginY={3} display="flex" justifyContent="center">
										<Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>데이터 가공처리 서비스</Typography>
									</Box>
									<StyledGrid container spacing={2} pb={10} >
										{
											dataProducts.map((e, i) =>
											{
												const delay = DEFAULT_DELAY + (i * 50);

												return (
													<Grid item xs={12} sm={4} md={4} sx={{ backgroundColor: "black" }}>
														<BoxWrap sx={{ backgroundColor: "black" }}>
															<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
																<ImageButtonWrap >
																	<Link to="/dataproductdetails" state={{ selectedId: i }}>
																		<DataImageButton
																			src={e.image} alt="상품 이름"
																		/>
																		{/* <BoxBefore />
																			<BoxAfter /> */}
																		<Info>
																			<InfoTitle>{e.title}</InfoTitle>
																		</Info>
																	</Link>
																</ImageButtonWrap>
															</SubBox>
														</BoxWrap>
													</Grid>);
											})
										}
									</StyledGrid>
								</Box>
							</Box>
						</Box>
						{/* </SwiperY > */}
					</Box >
					<Footer />
				</PC>
				<TABLET>
					<Box sx={{ height: "100%", marginTop: "100px", backgroundColor: "black" }}>
						<Box sx={{ width: "100%", height: "100%", backgroundColor: "black",}}>
							<Box paddingY={10} display="flex" justifyContent="center">
								<Typography data-aos="fade-up" data-aos-delay="500" variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>Product</Typography>
							</Box>
							<Box m={5} sx={{ height: "100%" }}>
								<Box marginY={3} display="flex" justifyContent="center">
									<Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>데이터 수집용 장비 하드웨어</Typography>
								</Box>
								<StyledGrid container spacing={1} sx={{ padding: "0px" }} >
									{
										products.map((e, i) =>
										{
											const delay = DEFAULT_DELAY + (i * 50);

											return (
												<Grid item xs={3} sm={3} sx={{ backgroundColor: "black", padding: "0px" }}>
													<MoBoxWrap sx={{ height: "100%", backgroundColor: "black" }}>
														<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
															<ImageButtonWrap >
																<Link to="/productdetails" state={{ selectedId: i }}>
																	<ImageButton
																		src={e.image} alt="상품 이름"
																	/>
																	<BoxBefore />
																	<BoxAfter />
																	<Info>
																		<MoInfoTitle>{e.title}</MoInfoTitle>
																	</Info>
																</Link>
															</ImageButtonWrap>
														</SubBox>
													</MoBoxWrap>
												</Grid>);
										})
									}
								</StyledGrid>
							</Box>
							<Box m={5} height="100%" paddingTop="100px" paddingBottom="100px" >
								<Box marginY={3} display="flex" justifyContent="center">
									<Typography variant="h5" sx={{ color: "#ffffff", fontWeight: "bold" }}>데이터 가공처리 서비스</Typography>
								</Box>
								<StyledGrid container spacing={2} pb={10} >
									{
										dataProducts.map((e, i) =>
										{
											const delay = DEFAULT_DELAY + (i * 50);

											return (
												<Grid item xs={12} sm={4} md={4} sx={{ backgroundColor: "black" }}>
													<BoxWrap sx={{ backgroundColor: "black" }}>
														<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
															<ImageButtonWrap >
																<Link to="/dataproductdetails" state={{ selectedId: i }}>
																	<DataImageButton
																		src={e.image} alt="상품 이름"
																	/>
																	{/* <BoxBefore />
																			<BoxAfter /> */}
																	{/* <Info>
																		<InfoTitle>{e.title}</InfoTitle>
																	</Info> */}
																</Link>
															</ImageButtonWrap>
														</SubBox>
													</BoxWrap>
												</Grid>);
										})
									}
								</StyledGrid>
							</Box>
						</Box>
						<Footer />
					</Box >
				</TABLET>
				<MOBILE>
					<Box sx={{ height: "100%", marginTop: "100px", backgroundColor: "black" }}>
						<Box sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingBottom: "50px" }}>
							<Box mb={3} display="flex" justifyContent="center">
								<Typography data-aos="fade-up" data-aos-delay="500" variant="h4" sx={{ color: "#ffffff", fontWeight: "bold" }}>Product</Typography>
							</Box>
							<Box mr={1} sx={{ height: "100%" }}>
								<Box marginY={3} display="flex" justifyContent="center">
									<Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>데이터 수집용 장비 하드웨어</Typography>
								</Box>
								<StyledGrid container spacing={1} sx={{ padding: "0px" }} >
									{
										products.map((e, i) =>
										{
											const delay = DEFAULT_DELAY + (i * 50);

											return (
												<Grid item xs={6} sm={6} sx={{ backgroundColor: "black", padding: "0px" }}>
													<MoBoxWrap sx={{ height: "100%", backgroundColor: "black" }}>
														<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
															<ImageButtonWrap >
																<Link to="/productdetails" state={{ selectedId: i }}>
																	<ImageButton
																		src={e.image} alt="상품 이름"
																	/>
																	<BoxBefore />
																	<BoxAfter />
																	<Info>
																		<MoInfoTitle>{e.title}</MoInfoTitle>
																	</Info>
																</Link>
															</ImageButtonWrap>
														</SubBox>
													</MoBoxWrap>
												</Grid>);
										})
									}
								</StyledGrid>
								<Box m={5} height="100%" paddingTop="50px" paddingBottom="100px" >
									<Box marginY={3} display="flex" justifyContent="center">
										<Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>데이터 가공처리 서비스</Typography>
									</Box>
									<StyledGrid container spacing={2} pb={10} >
										{
											dataProducts.map((e, i) =>
											{
												const delay = DEFAULT_DELAY + (i * 50);

												return (
													<Grid item xs={6} sm={6} sx={{ backgroundColor: "black" }}>
														<BoxWrap sx={{ backgroundColor: "black" }}>
															<SubBox data-aos="zoom-in" data-aos-delay={delay} data-aos-duration="900">
																<ImageButtonWrap >
																	<Link to="/dataproductdetails" state={{ selectedId: i }}>
																		<DataImageButton
																			src={e.image} alt="상품 이름"
																		/>
																		{/* <BoxBefore />
																			<BoxAfter /> */}
																		{/* <Info>
																		<InfoTitle>{e.title}</InfoTitle>
																	</Info> */}
																	</Link>
																</ImageButtonWrap>
															</SubBox>
														</BoxWrap>
													</Grid>);
											})
										}
									</StyledGrid>
								</Box>
							</Box>
						</Box>
						<Footer />
					</Box >
				</MOBILE >
			</>
		);
	}
}



