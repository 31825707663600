import React, { Component } from "react";

import { SwiperButton } from "./swiperbutton";

const EVENT_CHECK_TIME = 100;

// 네이버 브라우저는 scroll smooth 동작이 되지 않음.
const isNaver = navigator.userAgent.toLocaleLowerCase().indexOf("naver") !== -1 ? true : false;

class Slide extends Component
{
    constructor(props)
    {
        super(props);

        this.ref = React.createRef();
    }

    getHeight = () =>
    {
        return this.ref.current.clientHeight;
    }

    render()
    {
        const { children, footer } = this.props;

        return (
            <div ref={this.ref} style={{ height: footer ? "auto" : "100%" }}>
                {children}
            </div>
        );
    }
};

export default class SwiperY extends Component
{
    constructor(props)
    {
        super(props);

        // edge window.scrollY 5076.25 -> 4952.5
        this.state = {
            activeIndex: 0,
        };

        this.checkScrollTimer = -1;
        this.isScrolling = false;
        this.lastScrollTime = new Date().getTime();
        this.resizingTimer = -1;
        this.positions = [];
        this.touchStartPos = 0;
        this.slides = []; // ref callback
    }

    componentDidMount = () =>
    {
        // addEventListener의 passive: true 옵션은 브라우저에게 preventDefault()를 호출하지 않겠다고 알리는 역할을 합니다.
        window.addEventListener("wheel", this.handleWheel, { passive: false });
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("touchstart", this.handleTouchStart, { passive: true });
        window.addEventListener("touchmove", this.handleTouchMove, { passive: false });
        window.addEventListener("touchend", this.handleTouchEnd, { passive: true });
        window.addEventListener("resize", this.handleResize);

        this.updatePositions();
        this.updateIndex();

        // check scroll timer
        if (this.checkScrollTimer === -1)
        {
            this.checkScrollTimer = setInterval(() =>
            {
                const isScrolling = new Date().getTime() - this.lastScrollTime < EVENT_CHECK_TIME;
                if (this.isScrolling === true && isScrolling === false) // 정지할 때
                {
                    this.updateIndex();
                }
                this.isScrolling = isScrolling;
            }, 10);
        }
    };

    componentWillUnmount = () =>
    {
        clearInterval(this.checkScrollTimer);
        this.checkScrollTimer = -1; // debug mode reset

        clearTimeout(this.resizingTimer);

        window.removeEventListener("wheel", this.handleWheel);
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("touchstart", this.handleTouchStart);
        window.removeEventListener("touchmove", this.handleTouchMove);
        window.removeEventListener("touchend", this.handleTouchEnd);
        window.removeEventListener("resize", this.handleResize);
    };

    handleWheel = (event) =>
    {
        event.preventDefault();

        if (event.ctrlKey === false)
        {
            if (event.deltaY < 0)
            {
                this.slidePrev();
            }
            else
            {
                this.slideNext();
            }
        }
    };

    handleScroll = (event) =>
    {
        this.lastScrollTime = new Date().getTime();
    };

    handleTouchStart = (event) =>
    {
        this.touchStartPos = { x: Math.round(event.changedTouches[0].screenX), y: Math.round(event.changedTouches[0].screenY) };
    }

    handleTouchMove = (event) =>
    {
        event.preventDefault();
    };

    handleTouchEnd = (event) =>
    {
        const touchPos = { x: Math.round(event.changedTouches[0].screenX), y: Math.round(event.changedTouches[0].screenY) };
        const h = Math.abs(this.touchStartPos.x - touchPos.x);
        const v = Math.abs(this.touchStartPos.y - touchPos.y);

        if (h > v)
        {
            return;
        }

        if (v < 5)
        {
            return;
        }

        if (this.touchStartPos.y - touchPos.y > 0)
        {
            this.slideNext();
        }
        else
        {
            this.slidePrev();
        }

        this.touchStartPos = { ...touchPos };
    };

    handleResize = (event) =>
    {
        clearTimeout(this.resizingTimer);

        this.resizingTimer = setTimeout(() => 
        {
            this.updatePositions();
            this.updateIndex();
            this.resizingTimer = -1;
        }, 500);
    };

    updateIndex = () =>
    {
        const { activeIndex } = this.state;

        const scrollTop = Math.round(window.scrollY);  // 소수점 단위라 정수형으로

        const prevIndex = activeIndex;
        const lastIndex = this.slides.length - 1;
        let findIndex = -1;

        for (let i = 0; i < lastIndex; ++i)
        {
            const start = this.positions[i];
            const end = this.positions[i + 1];
            if (scrollTop >= start && scrollTop < end)
            {
                findIndex = i;
                break;
            }
        }

        if (findIndex === -1)
        {
            findIndex = lastIndex; // footer
        }

        if (prevIndex !== findIndex)
        {
            this.setState({ activeIndex: findIndex });

            const { onSlideChange } = this.props;
            if (onSlideChange)
            {
                onSlideChange(findIndex);
            }
        }
    };

    updatePositions = () =>
    {
        const length = this.slides.length;
        if (length)
        {
            const lastIndex = this.slides.length - 1;
            const height = this.slides[0].getHeight();
            const footer = length === 0 ? height : this.slides[lastIndex].getHeight();

            this.positions = Array.from({ length: length }, (_, i) => { return i === lastIndex ? height * (i - 1) + footer : height * i; });
        }
    };

    scrollToIndex = (index) =>
    {
        const top = this.positions[index];
        return this.scrollTo(top);
    };

    scrollTo = (top) =>
    {
        if (isNaver)
        {
            window.scrollTo({ left: 0, top: top });
        }
        else
        {
            window.scrollTo({ left: 0, top: top, behavior: "smooth" });
        }
    };

    slidePrev = () =>
    {
        const { activeIndex } = this.state;

        if (this.isScrolling)
        {
            return;
        }

        const prev = activeIndex === 0 && window.scrollY !== 0 ? 0 : activeIndex - 1;
        if (prev < 0)
        {
            return;
        }

        this.scrollTo(this.positions[prev]);
    };

    slideNext = () =>
    {
        const { activeIndex } = this.state;

        if (this.isScrolling)
        {
            return;
        }

        const lastIndex = this.slides.length - 1;
        const next = activeIndex === lastIndex && Math.ceil(window.scrollY) !== this.positions[lastIndex] ? lastIndex : activeIndex + 1;
        if (next > lastIndex)
        {
            return;
        }

        this.scrollTo(this.positions[next]);
    };

    render = () =>
    {
        const { children, paginationNames, hideDot } = this.props;
        const { activeIndex } = this.state;

        return (
            <div style={{ height: "100vh" }}>
                {
                    // 렌더가 되기 전에는 ref 할당이 되지 않음.
                    children.map((e, i) => { return <Slide key={i} ref={(ref) => this.slides[i] = ref } footer={i === children.length - 1}>{e}</Slide> })
                }
                <div style={{ display: "flex", flexDirection: "column", position: "fixed", height: "100%", right: "0px", top: "0px", justifyContent: "center", marginRight: "8px" }} >
                    {
                        paginationNames && paginationNames.map((e, i) =>
                        {
                            return <SwiperButton active={i === activeIndex} dot={!hideDot} key={i} onClick={() => this.scrollToIndex(i)}>{e}</SwiperButton>
                        })
                    }
                </div>
            </div>
        );
    };
};
