import React, { Component } from "react";

import { styled } from '@mui/material/styles';
import { Box, Typography, Grid } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";

const BusinessBox = styled(Box)({
	position: "relative",
	display: "flex",
	justifyContent: "center",
	width: "250px",
	height: "280px",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1
});

const TaBusinessBox = styled(Box)({
	position: "relative",
	display: "flex",
	justifyContent: "center",
	width: "150px",
	height: "200px",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1
});

const BusinessBoxTypography = styled(Typography)({
	position: "absolute",
	width: "220px",
	fontSize: "22px",
	fontWeight: "600",
	color: "white",
});

const TaBusinessBoxTypography = styled(Typography)({
	position: "absolute",
	width: "140px",
	fontSize: "16px",
	fontWeight: "600",
	color: "white",
});

const ModalBox = styled(Box)({
	display: "flex",
	justifyContent: "center",
	width: "100%",
	height: "800px"
});

const ModalTypographyBox = styled(Box)({
	width: "50%",
	flexGlow: 1
});

const ModalTypography = styled(Typography)({
	width: "80%",
	fontSize: "20px",
	fontWeight: 600,
});

const Modalimg = styled(Box)({
	width: "50%",
	height: "500px",
	flexGlow: 1

});

const MoBusinessBox = styled(Box)({
	position: "relative",
	display: "flex",
	justifyContent: "center",
	width: "150px",
	height: "150px",
	marginBottom: 10,
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1
});

const MoBusinessBoxTypography = styled(Typography)({
	position: "absolute",
	width: "120px",
	fontSize: "14px",
	fontWeight: "600",
	color: "white",
});

const BUSINESS_BOX_TEXT = [
	"2003\n 3D 전자기장 시뮬레이터 - MRI용 Gradient Coil Design Software 개발",
	"2005\n 소음의 강도 및 노출 시간에 따른 식물체의 생체광자 발생량 변화 패턴 연구",
	"2006\n 백업 경로 설정을 통한 AODV/MAODV 라우팅 프로토콜의 안전성 개선",
	"2008\n 4 AOS형 게임의 세부 기획 및 개발",
	"2009\n BCI 및 뉴로피드백 컨텐츠용 3D게임",
	"2009\n 뇌파측정용 EEG Hardware 설계 및 개발",
	"2010\n 3D 인터넷 메타데이터 운용 설계기술",
	"2010\n 근전도 측정 및 분석 프로그램",
	"2010\n 3D Brain Mapping",
	"2010\n SD card interface module",
	"2010\n 뇌전도 기반 바이오피드백(EEG-Biofeedback)기술을 이용한 자동차레이싱용 뇌파훈련 시스템",
	"2011\n 스펙트럼 3D기하 인터페이스 기술",
	"2011\n GPS 수신 및 환경데이터 측정장비 개발",
	"2011\n 심정도 측정 및 심박변이율 산출 장비",
	"2011\n 음원 주파수 특성 분석 프로그램",
	"2011\n Target image extension algorithm for SURF based object tracking",
	"2012\n 뉴로피드백 입문",
	"2009~2011\n IRST개발 기술자문 및 의료기기 제조품질 관리 허가 자문",
	"2013\n BIOS 개발(1 채널 소형 무선생체계측 모듈)",
	"2014\n BCG3",
	"2014\n WBMG8",
	"2014\n BPG9",
	"2014\n BPG24",
	"2016\n BIOS-S3, S8, S24, S40",
	"2016\n BIOS-mini",
	"2016\n BIOS-micro",
	"2016\n BioScan",
	"2017\n BIS-sub parameter calculation S/W",
	"2017\n BIOS HyperScan(Group Synchronization Measuring System)\n",
	"2017\n BIOS-KAERI 관련\n (KAERI)",
	"2017\n 정신건강모니터링 시스템\n (KISTI)",
	"2018\n BioScan Cloud Map3D S/W",
	"2018\n Easy Muscle Training System\n (한남대)",
	"2018\n BIOS-S24, BioSynch\n (대한뇌파생리학회)",
	"2018\n Visual Stimaulator for SSVEP\n (제이마플)",
	"2018\n BIOS, BioScan, BioFit\n (ETRI)",
	"2019\n LED Stimulator\n (조선대)",
	"2019\n Invoice phone number Recognition\n (제스엠텍)",
	"2019\n BioFit\n (중소기업부, 건양대)",
	"2019\n Specific logo Object detection\n (예림)",
	"2019\n BIOS-S24,BioSynch\n (대한뇌파생리학회)",
	"2019\n 2019 국제인공지능대전(AI EXPO KOREA) 참가",
	"2019\n Web board capable of replay and server data storage\n",
	"2019\n Speaker diarization Program\n (씨크릿우먼)",
	"2019\n Stress measurement and care program production\n",
	"2019\n Spine scan chair algorithm development\n (CBH)",
	"2019\n 다원 수면상태 센싱 시스템\n (SAFAUD)",
	"2019\n 자동 의복 재단치수 소프트웨어\n (충남대 의류학과)",
	"2020\n BIOS-ST",
	"2020\n 데이터스토어 입점",
	"2020\n 체험하며 배우는 WOLFRAM MATHEMATICA (Wolfram언어프로그래밍)",
	"2020\n 신항원 예측 서비스 플랫폼 제작\n (KISTI)",
	"2021\n AI Hub에 인공지능 모델 공급업체 등록",
	"2021\n CJ - 서울시 오벤터스 본선 참가",
	"2021\n 원통형 MRI 경사자계 코일 설계",
	"2021\n 핸즈온 및 생체계측 모듈",
	"2022\n BIOS-MI & KUBIOS Cloud",
	"2022\n 운동측정 및 분석 웹 플랫폼",
	"2022\n 영상AI 기반 자동 경혈점 위치인식 시스템",
	"2023\n 반도체 장비 상태측정기반 분석 플랫폼",
	"2023\n Web 기반 그림심리 평가 도구",
	"2023\n 열화상 데이터 전처리를 위한 DICOM 이미지 편집 도구",
	"2023\n MRI DICOM 파일기반 영상 연산 플랫폼",
	// ... 추가
].reverse();

export default class BusinessList extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			showModal: false,
			selectedBox: null,
			currentPage: 1,
			itemsPerPage: 8,
			moitemsPerPage: 6,
			fullWidth: true,
			maxWidth: 'xl',
			modalContents: [
				{
					image: process.env.PUBLIC_URL + "/assets/images/business1.png",
					typography: "다양한 생물정보학 데이터를 학습하여 맞춤형 신항원을 예측해주는 서비스 플랫폼",
				},
				{
					image: process.env.PUBLIC_URL + "/assets/images/business2.png",
					typography: "세계 최고의 과학기술 계산 프로그램 언어라고 할 수 있는 울프람 매스매티카 사용자를 위한 입문용 프로그래밍 서적인 Hands-On Start to Wolfram Mathematica의 한국어 번역작업에 참여",
				},
			]
		};
	}

	componentDidMount()
	{
		AOS.init();
	}

	handleBoxClick = (index) =>
	{
		this.setState({
			showModal: true,
			selectedBoxIndex: index,
		});
	};

	handleCloseModal = () =>
	{
		this.setState({
			showModal: false,
			selectedBox: null,
		});
	};

	handlePageChange = (event, value) =>
	{
		this.setState({
			currentPage: value,
		});
	};

	renderBoxes = () =>
	{
		const { currentPage, itemsPerPage } = this.state;

		const currentIndex = currentPage - 1;
		const quotient = parseInt(BUSINESS_BOX_TEXT.length / itemsPerPage);
		const remainder = BUSINESS_BOX_TEXT.length % itemsPerPage;
		const startIndex = currentIndex * itemsPerPage;
		const end = currentIndex === quotient ? startIndex + remainder : startIndex + itemsPerPage;

		// 6, 4

		const boxes = [];
		for (let i = startIndex; i < end; i++)
		{
			boxes.push(
				<Grid key={i} item xs={12} sm={6} lg={3} p={5} >
					<BusinessBox onClick={(e) => this.handleBoxClick(i)} ml={5} >
						<BusinessBoxTypography m={2}>{this.getBoxTypography(i)}</BusinessBoxTypography>
					</BusinessBox>
				</Grid>
			);
		}

		return boxes;
	};

	tarenderBoxes = () =>
	{
		const { currentPage, itemsPerPage } = this.state;

		const currentIndex = currentPage - 1;
		const quotient = parseInt(BUSINESS_BOX_TEXT.length / itemsPerPage);
		const remainder = BUSINESS_BOX_TEXT.length % itemsPerPage;
		const startIndex = currentIndex * itemsPerPage;
		const end = currentIndex === quotient ? startIndex + remainder : startIndex + itemsPerPage;

		// 6, 4

		const boxes = [];
		for (let i = startIndex; i < end; i++)
		{
			boxes.push(
				<Grid key={i} item xs={6} sm={3} lg={3}>
					<TaBusinessBox onClick={(e) => this.handleBoxClick(i)} m={1} >
						<TaBusinessBoxTypography m={2}>{this.getBoxTypography(i)}</TaBusinessBoxTypography>
					</TaBusinessBox>
				</Grid>
			);
		}

		return boxes;
	};

	mobilerenderBoxes = () =>
	{
		const { currentPage, moitemsPerPage } = this.state;

		const currentIndex = currentPage - 1;
		const quotient = parseInt(BUSINESS_BOX_TEXT.length / moitemsPerPage);
		const remainder = BUSINESS_BOX_TEXT.length % moitemsPerPage;
		const startIndex = currentIndex * moitemsPerPage;
		const end = currentIndex === quotient ? startIndex + remainder : startIndex + moitemsPerPage;

		// 6, 4

		const boxes = [];
		for (let i = startIndex; i < end; i++)
		{
			boxes.push(
				<Grid key={i} item xs={6} sm={6} lg={3}>
					<MoBusinessBox onClick={(e) => this.handleBoxClick(i)}>
						<MoBusinessBoxTypography m={1}>{this.getBoxTypography(i)}</MoBusinessBoxTypography>
					</MoBusinessBox>
				</Grid>
			);
		}

		return boxes;
	};

	renderModalContent = () =>
	{
		const { selectedBoxIndex, modalContents } = this.state;

		if (modalContents[selectedBoxIndex])
		{
			const { typography, image } = modalContents[selectedBoxIndex];

			return (
				<ModalBox>
					<ModalTypographyBox m={1}>
						<ModalTypography>{typography}</ModalTypography>
					</ModalTypographyBox>
					<Modalimg m={1} component="img" src={image} alt="모달 이미지" />
				</ModalBox>
			);
		}
	};

	getBoxTypography = (index) =>
	{
		return BUSINESS_BOX_TEXT[index];
	};

	render()
	{
		const { currentPage, itemsPerPage, } = this.state;
		const totalItems = BUSINESS_BOX_TEXT.length;
		const totalPages = Math.ceil(totalItems / itemsPerPage);

		return (
			<>
				<PC>
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100vh", paddingTop: "100px" }}>
						<Box mt={5} sx={{ height: "100%" }}>
							<Box mb={5} display="flex" justifyContent="center">
								<Typography variant="h3" sx={{ color: "#ffffff", fontWeight: "bold" }}>PORTFOLIO</Typography>
							</Box>
							<Box sx={{ color: "white" }}>
								<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
									<Grid container spacing={0.5}>
										{this.renderBoxes()}
									</Grid>
								</Box>
								<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
									<Stack spacing={2}>
										<Pagination
											count={totalPages}
											page={currentPage}
											defaultPage={6}
											siblingCount={0}
											onChange={this.handlePageChange}
											sx={{
												width: "100%",
												backgroundColor: "black",
												color: "white",
												"& .MuiPaginationItem-root": {
													padding: "0px",
													borderRadius: "50%",
													width: "30px",
													height: "30px",
													backgroundColor: "black",
													color: "white",
													"&.Mui-selected": {
														backgroundColor: "black",
														border: "1px solid #4dabcf",
														fontWeight: "bold"
													},
												},
												"& .MuiPaginationItem-icon": {
													color: "white",
													width: "50px",
													height: "50px",
													backgroundColor: "black"
												},
											}}
										/>
									</Stack>
								</Box>
							</Box>
						</Box>
					</Box>
				</PC>
				<TABLET>
					<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100%", paddingLeft: 2 }}>
						<Box display="flex" justifyContent="center" sx={{ paddingTop: "100px" }}>
							<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%", whiteSpace: "pre-line", backgroundColor: "black" }} >
								<Box justifyContent="center" sx={{ paddingBottom: "10px" }}>
									<Box>
										<Grid container>
											{this.tarenderBoxes()}
										</Grid>
									</Box>
									<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
										<Stack spacing={1}>
											<Pagination
												size="small"
												count={totalPages}
												page={currentPage}
												defaultPage={6}
												siblingCount={0}
												onChange={this.handlePageChange}
												shape="rounded"
												variant="fullWidth"
												sx={{
													width: "100%",
													backgroundColor: "black",
													color: "white",
													margin: "0px",
													"& .MuiPaginationItem-root": {
														padding: "0px",
														borderRadius: "50%",
														width: "15px",
														height: "20px",
														backgroundColor: "black",
														color: "white",
														margin: "0px",
														fontSize: "12px",
														"&.Mui-selected": {
															backgroundColor: "black",
															border: "1px solid #4dabcf",
															fontSize: "12px",
															fontWeight: "bold",
															margin: "0px",
															padding: "0px",
														},
													},
													"& .MuiPaginationItem-icon": {
														color: "white",
														width: "35px",
														height: "30px",
														backgroundColor: "black",
														margin: "0px",
														padding: "0px",
													},
												}}
											/>
										</Stack>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</TABLET>
				<MOBILE>
					<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100vh" }}>
						<Box display="flex" justifyContent="center" sx={{ paddingTop: "100px" }}>
							<Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%", whiteSpace: "pre-line", backgroundColor: "black" }} >
								<Box justifyContent="center" sx={{ paddingBottom: "10px" }}>
									<Box pl={3}>
										<Grid container>
											{this.mobilerenderBoxes()}
										</Grid>
									</Box>
									<Box display="flex" justifyContent="center" sx={{ width: "100%", height: "100%" }}>
										<Stack spacing={1}>
											<Pagination
												size="small"
												count={totalPages}
												page={currentPage}
												defaultPage={6}
												siblingCount={0}
												onChange={this.handlePageChange}
												shape="rounded"
												variant="fullWidth"
												sx={{
													width: "100%",
													backgroundColor: "black",
													color: "white",
													margin: "0px",
													"& .MuiPaginationItem-root": {
														padding: "0px",
														borderRadius: "50%",
														width: "15px",
														height: "20px",
														backgroundColor: "black",
														color: "white",
														margin: "0px",
														fontSize: "12px",
														"&.Mui-selected": {
															backgroundColor: "black",
															border: "1px solid #4dabcf",
															fontSize: "12px",
															fontWeight: "bold",
															margin: "0px",
															padding: "0px",
														},
													},
													"& .MuiPaginationItem-icon": {
														color: "white",
														width: "35px",
														height: "30px",
														backgroundColor: "black",
														margin: "0px",
														padding: "0px",
													},
												}}
											/>
											{/* {true ?
											<Dialog
												data-aos="fade-down"
												data-aos-delay="600"
												data-aos-duration="900"
												fullWidth={fullWidth}
												maxWidth={maxWidth}
												open={showModal}
												onClose={this.handleCloseModal}
												sx={{ width: "100%", display: "flex", justifyContent: "center" }}
											>
												<DialogTitle>
													<IconButton
														aria-label="close"
														onClick={this.handleCloseModal}
														sx={{
															position: "absolute",
															right: 8,
															top: 8,
														}}
													>
														<CloseIcon />
													</IconButton>
												</DialogTitle>
												{this.renderModalContent()}
											</Dialog>
											: <></>} */}
										</Stack>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</MOBILE>
			</>
		);
	}
}