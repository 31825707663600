import React, { Component } from "react";

import { Outlet } from "react-router-dom";

/* Sub Components */
import Menu from "./menu.jsx";
import Footer from "./footer.jsx";

class Layout extends Component
{
	render()
	{
		return (
			<React.Fragment>
				<Menu />
				<Outlet />
				{/* <Footer /> */}
			</React.Fragment>
		);
	}
}

/* Exports */
export default Layout;
