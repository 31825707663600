import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default class SpecTable extends Component
{
	render()
	{
		// const { data } = this.props;
		const { biosignalChannel, biosignalMonopolarChannel, biosignalBipolarChannel, samplingRate, noise, adConversion, inputImpedance, leadOnOffChecking, powerSupply, communicationInterface, software, size, weight, channelNumber, resolution, bandwidth, filtering, dynamicRange, motionSensors, connectivity, outputFileFormat, dataTransformation, battery, operatingTime, eventLock, eventJitter, supportedOS } = this.props;

		return (
			<>
				<TableContainer component={Paper} sx={{ maxWidth: 450 }} >
					<Table aria-label="simple table">
						<TableBody>
							{
								biosignalChannel ?
									<TableRow key={0}>
										<TableCell component="th" scope="row">{"Biosignal channel"}</TableCell>
										<TableCell>{biosignalChannel}</TableCell>
									</TableRow> : <></>
							}
							{biosignalMonopolarChannel ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Biosignal monopolar channel"}</TableCell>
									<TableCell>{biosignalMonopolarChannel}</TableCell>
								</TableRow> : <></>
							}
							{biosignalBipolarChannel ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Biosignal bipolar channel"}</TableCell>
									<TableCell>{biosignalBipolarChannel}</TableCell>
								</TableRow> : <></>
							}
							{samplingRate ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Sampling Rate"}</TableCell>
									<TableCell>{samplingRate}</TableCell>
								</TableRow> : <></>
							}
							{noise ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Noise"}</TableCell>
									<TableCell>{noise}</TableCell>
								</TableRow> : <></>
							}
							{adConversion ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Ad conversion"}</TableCell>
									<TableCell>{adConversion}</TableCell>
								</TableRow> : <></>
							}
							{inputImpedance ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Input Impedance"}</TableCell>
									<TableCell>{inputImpedance}</TableCell>
								</TableRow> : <></>
							}
							{leadOnOffChecking ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Lead on/off checking"}</TableCell>
									<TableCell>{leadOnOffChecking}</TableCell>
								</TableRow> : <></>
							}
							{powerSupply ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Power supply"}</TableCell>
									<TableCell>{powerSupply}</TableCell>
								</TableRow> : <></>
							}
							{communicationInterface ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Communication Interface"}</TableCell>
									<TableCell>{communicationInterface}</TableCell>
								</TableRow> : <></>
							}
							{software ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"software"}</TableCell>
									<TableCell>{software}</TableCell>
								</TableRow> : <></>
							}
							{size ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Size"}</TableCell>
									<TableCell>{size}</TableCell>
								</TableRow> : <></>
							}
							{weight ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Weight"}</TableCell>
									<TableCell>{weight}</TableCell>
								</TableRow> : <></>
							}
							{channelNumber ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Channel Number"}</TableCell>
									<TableCell>{channelNumber}</TableCell>
								</TableRow> : <></>
							}
							{resolution ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Resolution"}</TableCell>
									<TableCell>{resolution}</TableCell>
								</TableRow> : <></>
							}
							{bandwidth ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Bandwidth"}</TableCell>
									<TableCell>{bandwidth}</TableCell>
								</TableRow> : <></>
							}
							{filtering ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Filtering"}</TableCell>
									<TableCell>{filtering}</TableCell>
								</TableRow> : <></>
							}
							{dynamicRange ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Dynamic Range"}</TableCell>
									<TableCell>{dynamicRange}</TableCell>
								</TableRow> : <></>
							}
							{motionSensors ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Motion Sensors"}</TableCell>
									<TableCell>{motionSensors}</TableCell>
								</TableRow> : <></>
							}
							{connectivity ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Connectivity"}</TableCell>
									<TableCell>{connectivity}</TableCell>
								</TableRow> : <></>
							}
							{outputFileFormat ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Output File Format"}</TableCell>
									<TableCell>{outputFileFormat}</TableCell>
								</TableRow> : <></>
							}
							{dataTransformation ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Data Transformation"}</TableCell>
									<TableCell>{dataTransformation}</TableCell>
								</TableRow> : <></>
							}
							{battery ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Battery"}</TableCell>
									<TableCell>{battery}</TableCell>
								</TableRow> : <></>
							}
							{operatingTime ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Operating Time"}</TableCell>
									<TableCell>{operatingTime}</TableCell>
								</TableRow> : <></>
							}
							{battery ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Battery"}</TableCell>
									<TableCell>{battery}</TableCell>
								</TableRow> : <></>
							}
							{eventLock ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"EventLock"}</TableCell>
									<TableCell>{eventLock}</TableCell>
								</TableRow> : <></>
							}
							{eventJitter ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Event Jitter"}</TableCell>
									<TableCell>{eventJitter}</TableCell>
								</TableRow> : <></>
							}
							{supportedOS ?
								<TableRow key={0}>
									<TableCell component="th" scope="row">{"Supported OS"}</TableCell>
									<TableCell>{supportedOS}</TableCell>
								</TableRow> : <></>
							}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	}
};


