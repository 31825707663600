import React, { Component } from 'react';
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/material';

import { MOBILE, TABLET, PC } from "./global";

import SpecTable from './spectable';
import SoftwareTable from './softwaretable';
import Footer from './footer';

const DetailContainerBox = styled(Box)({
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "center",
	// alignItems: "center",
	width: "100vh"
});

const DetailBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
});

const TopTypography = styled(Typography)({
	fontSize: "70px",
	fontWeight: "600",
});

const SubTypography = styled(Typography)({
	fontSize: "18px"
});

const ProductImgBox = styled(Box)({
	// margin: 30,
	overFlow: "hidden",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center"
});

// const DetailSubImgBox = styled(Box)({
// 	width: "150px",
// 	height: "150px"
// });

const TableTypography = styled(Typography)({
	fontSize: "20px",
	fontWeight: "600",
	textTransform: "none"
});

const zoomIn = keyframes`
from {
    transform: scale(1);
}

to {
    transform: scale(1.2);
}
`;

const BoxZoomIn = styled(Box)(({ theme }) => ({
	// animation: `${zoomIn} 10s ease-out`,
	// animationFillMode: "forwards",
	// "&:hover": {
	// 	// cursor: "pointer",
	// },
}));

const MoDetailBox = styled(Box)({
	marginBottom: "3rem",
	alignItems: "center",
});

const MoTopTypography = styled(Typography)({
	fontSize: "30px",
	fontWeight: "600",
});

const MoSubTypography = styled(Typography)({
	fontSize: "15px"
});

const MoProductImgBox = styled(Box)({
	padding: 10,
	overFlow: "hidden",
	display: "flex",
	justifyContent: "flex-end"
});

const MoDetailSubImgBox = styled(Box)({
	width: "100px",
	height: "100px"
});

// const MoTableTypography = styled(Typography)({
// 	fontSize: "20px",
// 	fontWeight: "600"
// });

class TabPanel extends Component
{

	render()
	{
		const { children, value, index, ...other } = this.props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}

			>
				{value === index && (
					<Box sx={{ p: 5 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index)
{
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

class ProductDetails extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			value: 0,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount()
	{
		window.scrollTo({ left: 0, top: 0 });

		const { selectedId } = this.props;
		console.log(selectedId);
		this.setState({ value: selectedId });
	}

	handleChange(event, newValue)
	{
		console.log(newValue);
		this.setState({ value: newValue });
	}

	render()
	{
		const { value } = this.state;

		const paneldata = [

			{
				id: 0,
				title: "DT-BI",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "DATA PROCESSING TECHNOLOGY",

				mainimg: process.env.PUBLIC_URL + '/assets/images/DT-BI.png',
				subimg1: {},
				subimg2: {},
				subimg3: {},

				tabletitle: "DT-BI SPECIFICATION",
				infomation: "DT-BI는 생명(생물)정보학 데이터로서 다양한 원천에 따라서 다양한 정보를 지닌 데이터이다.\n 유전체 염기서열, 단백질 구조, 펩타이드 서열, 마이크로어레이, 단백질 네트워크, 메타볼라이트, 분자상호작용 등의  데이터 사례가 있을 수 있다. 이러한 생명정보학 데이터에서 아티팩트를 제거하고 정제하여 전처리된 데이터를 제공하고 고객의 필요에 따라서는 데이터의 생물정보학적 특징을 추출해서 함께 제공하기도 한다.",
				spectable: {},
				softwaretable: {}
			},
			{
				id: 1,
				title: "DT-TS",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "DATA PROCESSING TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/DT-TS.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/BIOFITd2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				tabletitle: "DT-TS SPECIFICATION",
				infomation: "DT-TS는 시간에 따라서 변동하는 시계열신호를 이산디지털로 표본화하여 획득한 데이터를 의미하며, 표본화 주파수, 비트 분해능, 표에 따라서 데이터의 세밀도와 용량이 결정된다. 인체에서 신경생리학적인 신호들이 있으며, 이것을 계측한 사례로 EEG, EMG, ECG, EOG, EGG, PPG, MEG, MCG 데이터 등으로 다양하다.\n 가속도 3축, 자이로 3축, 지자계 3축 등의 관성센싱을 한 데이터도 있고 날과 시간에 따라서 변화하는 기상데이터, 주가데이터도 있겠다.\n 이러한 시계열 데이터에서 잡파등을 제거하고 정제하여 전처리된 데이터를 제공하고 고객의 필요에 따라서는 데이터의 시간적 특징을 추출해서 함께 제공하기도 한다.",
				spectable: {},
				softwaretable: {}
			},
			{
				id: 2,
				title: "DT-TX",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "DATA PROCESSING TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/DT-TX.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/BIOFITd2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				tabletitle: "DT-TX SPECIFICATION",
				infomation: "DT-TX는 텍스트(문헌) 데이터로서 다양한 원천에 따라서 다양한 정보를 지닌 문자 또는 문자코드 데이터이다.\n 다양한 분야의 문헌등에서 문자코드로 정제하여 표현하고 문맥등을 파악하여 연결 또는 네트워크등 형태로 표현할 수도 있다.\n 이러한 텍스트 데이터에서 아티팩트를 제거하고 정제하여 전처리된 텍스트 데이터를 제공하고 고객의 필요에 따라서 NLP(자연어처리), NLU(자연어이해)등에 활용될 수 있도록, Textual Processing을 하여 텍스트의 특징을 추출해서 함께 제공하기도 한다.",
				spectable: {},
				softwaretable: {}
			},
			{
				id: 3,
				title: "DT-VD",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "DATA PROCESSING TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/DT-VD.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/BIOFITd2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				tabletitle: "DT-VD SPECIFICATION",
				infomation: "DT-VD는 영상데이터로서 2차원배열의 크기, 색상채널수, 색상분해능에 따라서 데이터의 세밀도와 용량이 결정 된다.\n 폰을 통해서 촬영한 사진 데이터와 MRI, CT, 초음파, PET 등의 의료영상 데이터, 열화상 데이터, 인공위성에서 촬용한 지리적 데이터 등의 사례가 있을 수 있다.\n 이러한 영상 데이터에서 아티팩트를 제거하고 정제하여 전처리된 데이터를 제공하고 고객의 필요에 따라서는 데이터의 공간적 특징을 추출해서 함께 제공하기도 한다.",
				spectable: {},
				softwaretable: {}
			},
		]

		return (
			<>
				<PC>
					<Box pt={10}
						sx={{ bgcolor: 'background.paper', display: 'flex', whiteSpace: "pre-wrap" }}
					>
						<Tabs
							orientation="vertical"
							variant="scrollable"
							value={value}
							onChange={this.handleChange}
							aria-label="Vertical tabs example"
							sx={{ width: "200px", borderRight: 1, borderColor: 'divider' }}
						>
							<Tab label="DT-BI" {...a11yProps(0)} />
							<Tab label="DT-TS" {...a11yProps(1)} />
							<Tab label="DT-TX" {...a11yProps(2)} />
							<Tab label="DT-VD" {...a11yProps(3)} />
						</Tabs>
						{
							paneldata.map((e, i) => 
							{
								// console.log(e.spectable.biosignalChannel);
								return (
									<TabPanel value={value} index={e.id} >
										<Box height="100vh">
											<Box height="100%" display="flex" flexDirection="column" justifyContent="center" >
												<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
													<BoxZoomIn component="img" sx={{ width: "300px", height: "200px" }} src={e.mainimg} alt="상품 이름" />
												</Box>
												<Box>
													<Box display="flex" justifyContent="center">
														<Box display="flex" flexDirection="column" justifyContent="center">
															<Box mt={10} display="flex" justifyContent="center" sx={{ width: "100%", height: "500px" }}>
																<Typography variant="h6" sx={{ whiteSpace: "pre-line", width: "65%", fontWeight: "700" }} >{e.infomation}</Typography>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
								)
							})
						}
					</Box >
					<Footer />
				</PC>
				<TABLET>
					<Box pt={15} sx={{ width: "100%", bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={this.handleChange}
							variant="scrollable"
							scrollButtons
							allowScrollButtonsMobile
							aria-label="scrollable force tabs example"
						>
							<Tab label="DT-BI" {...a11yProps(0)} />
							<Tab label="DT-TS" {...a11yProps(1)} />
							<Tab label="DT-TX" {...a11yProps(2)} />
							<Tab label="DT-VD" {...a11yProps(3)} />
						</Tabs>
						{
							paneldata.map((e, i) => 
							{
								// console.log(e.spectable.biosignalChannel);
								return (
									<TabPanel value={value} index={e.id} >
										<Box height="100%">
											<Box height="100%" display="flex" flexDirection="column" justifyContent="center" >
												<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
													<BoxZoomIn component="img" sx={{ width: "300px", height: "200px" }} src={e.mainimg} alt="상품 이름" />
												</Box>
												<Box>
													<Box display="flex" justifyContent="center">
														<Box display="flex" flexDirection="column" justifyContent="center">
															<Box my={10} display="flex" justifyContent="center" sx={{ width: "100%" }}>
																<Typography variant="h6" sx={{ whiteSpace: "pre-line", width: "90%", fontWeight: "700" }} >{e.infomation}</Typography>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
								)
							})
						}
					</Box>
					<Footer />
				</TABLET>
				<MOBILE>
					<Box pt={15} sx={{ width: "100%", bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={this.handleChange}
							variant="scrollable"
							scrollButtons
							allowScrollButtonsMobile
							aria-label="scrollable force tabs example"
						>
							<Tab label="DT-BI" {...a11yProps(0)} />
							<Tab label="DT-TS" {...a11yProps(1)} />
							<Tab label="DT-TX" {...a11yProps(2)} />
							<Tab label="DT-VD" {...a11yProps(3)} />
						</Tabs>
						{
							paneldata.map((e, i) => 
							{
								// console.log(e.spectable.biosignalChannel);
								return (
									<TabPanel value={value} index={e.id} >
										<Box height="100%">
											<Box height="100%" display="flex" flexDirection="column" justifyContent="center" >
												<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
													<BoxZoomIn component="img" sx={{ width: "300px", height: "200px" }} src={e.mainimg} alt="상품 이름" />
												</Box>
												<Box>
													<Box display="flex" justifyContent="center">
														<Box display="flex" flexDirection="column" justifyContent="center">
															<Box mt={10} display="flex" justifyContent="center" sx={{ width: "100%" }}>
																<Typography variant="h6" sx={{ whiteSpace: "pre-line", width: "90%", fontWeight: "700" }} >{e.infomation}</Typography>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</TabPanel>
								)
							})
						}
					</Box>
					<Footer />
				</MOBILE>
			</>
		);
	}
};

const DataProductDetailsWrap = props =>
{
	const { state } = useLocation();
	const { selectedId } = state;
	console.log(selectedId);
	return <ProductDetails selectedId={selectedId} {...props} />
};

export default DataProductDetailsWrap;
