import React, { Component } from 'react';
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/material';

import { MOBILE, TABLET, PC } from "./global";

import SpecTable from './spectable';
import SoftwareTable from './softwaretable';
import Footer from './footer';

const DetailContainerBox = styled(Box)({
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "space-between",
	width: "100vh"
});

const DetailBox = styled(Box)({
	marginBottom: "3rem"
});

const TopTypography = styled(Typography)({
	fontSize: "70px",
	fontWeight: "600",
});

const SubTypography = styled(Typography)({
	fontSize: "18px"
});

const ProductImgBox = styled(Box)({
	margin: 30,
	overFlow: "hidden",
	display: "flex",
	justifyContent: "flex-end"
});

const DetailSubImgBox = styled(Box)({
	width: "150px",
	height: "150px"
});

const TableTypography = styled(Typography)({
	fontSize: "20px",
	fontWeight: "600",
	textTransform: "none"
});

const zoomIn = keyframes`
from {
    transform: scale(1);
}

to {
    transform: scale(1.2);
}
`;

const BoxZoomIn = styled(Box)(({ theme }) => ({
	animation: `${zoomIn} 10s ease-out`,
	animationFillMode: "forwards",
	"&:hover": {
		// cursor: "pointer",
	},
}));

const MoDetailBox = styled(Box)({
	marginBottom: "3rem",
	alignItems:"center",
});

const MoTopTypography = styled(Typography)({
	fontSize: "30px",
	fontWeight: "600",
});

const MoSubTypography = styled(Typography)({
	fontSize: "15px"
});

const MoProductImgBox = styled(Box)({
	padding: 10,
	overFlow: "hidden",
	display: "flex",
	justifyContent: "flex-end"
});

const MoDetailSubImgBox = styled(Box)({
	width: "100px",
	height: "100px"
});

// const MoTableTypography = styled(Typography)({
// 	fontSize: "20px",
// 	fontWeight: "600"
// });

class TabPanel extends Component
{

	render()
	{
		const { children, value, index, ...other } = this.props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}

			>
				{value === index && (
					<Box sx={{ p: 5 }}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index)
{
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

class ProductDetails extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {
			value: 0,
		};

		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount()
	{
		window.scrollTo({ left: 0, top: 0 });

		const { selectedId } = this.props;
		console.log(selectedId);
		this.setState({ value: selectedId });
	}

	handleChange(event, newValue)
	{
		console.log(newValue);
		this.setState({ value: newValue });
	}

	render()
	{
		const { value } = this.state;

		const paneldata = [
			{
				id: 0,
				title: "BIOS - mini",
				subtitle: "Wireless Biosignal",
				subtitle2: "Measurement System for Reasearch",
				subtitlestress: "(EMG, ECG)",
				mainimg: process.env.PUBLIC_URL + "/assets/images/minid1.png",
				tamainimg: process.env.PUBLIC_URL + "/assets/images/taminid1.png",
				subimg1: process.env.PUBLIC_URL + '/assets/images/minid2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/minid3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/minid4.png',
				tabletitle: "BIOS - mini SPECIFICATION",
				spectable: {
					biosignalChannel: "up to 8channel",
					samplingRate: "1kHz / channel",
					noise: "< 8uVpp",
					adConversion: "24bit [Analog to Digital",
					inputImpedance: "> 1Gohm",
					leadOnOffChecking: "S/W interface",
					powerSupply: "Rechargeable Battery",
					communicationInterface: "Wifi",
					software: "Bioscan for ECG, EMG Analysis",
					size: "mini : 30 x 45 x 16.5mm\nCradle : 120 x 200 x 70mm (8channel charging)",
					weight: "< 22g (Without cradle)",
				},
				softwaretitle: "BIOS - mini SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 1,
				title: "BIOS - S8",
				subtitle: "Wireless Biosignal",
				subtitle2: "Measurement System for Reasearch",
				subtitlestress: "(EEG, ECG, EMG, EOG)",
				mainimg: process.env.PUBLIC_URL + '/assets/images/S8d1.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/S8d2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/S8d3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/S8d4.png',
				tabletitle: "BIOS-S8 SPECIFICATION",
				spectable: {
					biosignalMonopolarChannel: "1-8ch for EEG",
					biosignalBipolarChannel: "1-8ch for ECG, EMG, EOG",
					samplingRate: "250, 500, 1000Hz / channel",
					noise: "< 1uVpp [@250Hz]",
					adConversion: "24bit [Analog to Digita]",
					inputImpedance: "> 1Gohm",
					leadOnOffChecking: "S/W interface",
					powerSupply: "USB",
					communicationInterface: "USB",
					software: "Bioscan for EEG, ECG, EMG Analysis",
					size: "92 x 62 x 40mm [Slim]",
					weight: "< 152.5g",
				},
				softwaretitle: "BIOS-S8 SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 2,
				title: "BIOS - S24",
				subtitle: "Biosignal",
				subtitle2: "Measurement System for Reasearch",
				subtitlestress: "(EEG, ECG, EMG, EOG)",
				mainimg: process.env.PUBLIC_URL + '/assets/images/S24d1.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/S24d2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/S24d3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/S24d4.png',
				tabletitle: "BIOS-S24 SPECIFICATION",
				spectable: {
					biosignalMonopolarChannel: "21ch for EEG",
					biosignalBipolarChannel: "3ch for ECG, EMG, EOG",
					samplingRate: "250, 500, 1000Hz / channel",
					noise: "< 1uVpp [@250Hz",
					adConversion: "24bit [Analog to Digital]",
					inputImpedance: "> 1Gohm",
					leadOnOffChecking: "S/W interface",
					powerSupply: "USB",
					communicationInterface: "USB",
					software: "Bioscan for EEG, ECG, EMG Analysis",
					size: "60 x 160 x 25mm [Slim]",
					weight: "< 400g"
				},
				softwaretitle: "BIOS-S24 SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 3,
				title: "BIOS - S40",
				subtitle: "Biosignal",
				subtitle2: "Measurement System for Reasearch",
				subtitlestress: "(EEG, ECG, EMG, EOG)",
				mainimg: process.env.PUBLIC_URL + '/assets/images/S40d1.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/S40d2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/S40d3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/S40d4.png',
				tabletitle: "BIOS-S40 SPECIFICATION",
				spectable: {
					biosignalMonopolarChannel: "32ch for EEG",
					biosignalBipolarChannel: "8ch for ECG, EMG, EOG",
					samplingRate: "250, 500, 1000Hz / channe",
					noise: "< 1uVpp [@250Hz",
					adConversion: "24bit [Analog to Digital]",
					inputImpedance: "> 1Gohm",
					leadOnOffChecking: "S/W interface",
					powerSupply: "USB",
					communicationInterface: "USB",
					software: "Bioscan for EEG, ECG, EMG Analysis",
					size: "140 x 175 x 30mm [Slim]",
					weight: "< 450g"
				},
				softwaretitle: "BIOS-S40 SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 4,
				title: "BIOS - ST",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "BIOS-INFORMATION TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/STd1.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/STd2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/STd3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/STd4.png',
				tabletitle: "BIOS-ST SPECIFICATION",
				spectable: {
					channelNumber: "8 signal channels + 2 REF",
					samplingRate: "1000 HZ",
					resoluiton: "24 bits",
					bandwidth: "DC to 125 HZ",
					filtering: "Software 50/60 HZ notch",
					dynamicRange: "+/- 375 mV",
					motionSensors: "6-axis",
					connectivity: "Proprietary RF receiver",
					outputFileFormat: "CSV, EDF",
					dataTransformation: "RAW, BAND POWER, Z-SCORE",
					battery: "Rechargeable Li-Poly 500 mAh",
					operatingTime: "10 hours",
					eventLock: "RS232",
					eventJitter: "±1mx",
					supportedOS: "Windows 10"
				},
				softwaretitle: "BIOS-ST SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 5,
				title: "BIOS - MICRO",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "BIOS-INFORMATION TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/bios_micro.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/microd2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/microd3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/microd4.png',
				tabletitle: "BIOS-MICRO SPECIFICATION",
				spectable: {
					biosignalMonopolarChannel: "1-8ch for EEG",
					biosignalBipolarChannel: "1-8ch for ECG, EMG, EOG",
					samplingRate: "250, 500, 1000, 2000, 4000Hz / channel",
					noise: "< 1uVpp [@250Hz]",
					adConversion: "24bit [Analog to Digital]",
					inputImpedance: "> 1Gohm",
					powerSupply: "Rely on EVM (USB, Battery)",
					communicationInterface: "Rely on EVM (USB, Wifi, Bluetooth)",
					software: "Bioscan for EEG, ECG, EMG Analysis",
					size: "micro : 22.5 x 20 x 2mm \n EVM : 120 x 65 x 1.6mm (Option)",
					weight: "micro : < 5g \n EVM : < 60g"
				},
				softwaretitle: "BIOS-MICRO SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 6,
				title: "BIOS - S3",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "BIOS-INFORMATION TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/bios_s3.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/S3d2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/S3d3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/S3.png',
				tabletitle: "BIOS-S3 SPECIFICATION",
				spectable: {
					biosignalMonopolarChannel: "2ch for EEG",
					biosignalBipolarChannel: "1ch for ECG, EMG, EOG",
					samplingRate: "250, 500, 1000Hz / channel",
					noise: "< 1uVpp [@250Hz",
					adConversion: "24bit [Analog to Digital]",
					inputImpedance: "> 1Gohm",
					leadOnOffChecking: "S/W interface",
					powerSupply: "USB",
					communicationInterface: "USB",
					software: "Bioscan for ECG, EMG Analysis",
					size: "108 x 88 x 22mm [Slim]",
					weight: "< 138g"
				},
				softwaretitle: "BIOS-S3 SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 7,
				title: "BIOS - SW8",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "BIOS-INFORMATION TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/bios1_ws8.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/ws8d2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/ws8d3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/ws8d4.png',
				tabletitle: "BIOS-SW8 SPECIFICATION",
				spectable: {
					biosignalMonopolarChannel: "1-8ch for EEG",
					biosignalBipolarChannel: "1-8ch for ECG, EMG, EOG",
					samplingRate: "250, 500, 1000Hz / channel",
					noise: "< 1uVpp [@250Hz]",
					adConversion: "24bit [Analog to Digital]",
					inputImpedance: "> 1Gohm",
					leadOnOffChecking: "S/W interface",
					powerSupply: "Battery",
					communicationInterface: "Wifi",
					software: "Bioscan for EEG, ECG, EMG Analysis",
					size: "90 x 45 x 13mm [Slim]",
					weight: "< 30g"
				},
				softwaretitle: "BIOS-SW8 SOFTWARE",
				softwaretable: {
					acquisition: "Device, Channel Selection\nSampling Rate & Timer Setting",
					signalAnalysis: "Time Domain, Freq.\nDomain Signal Ensemble Averaging by\nEvents Synchrony Analysis",
					display: "Time-Series Graph, Scatter plot,\nPhase plot, Pie-chart, Bar-Chart,\nMapping Package (Option)",
					synchronizationWithOtherSW: "Eprime [Option] - for EEG / ERP\n Experiment"
				}
			},
			{
				id: 8,
				title: "BIOFIT",
				subtitle: "WE BRING SIMPLICITY TO",
				subtitle2: "BIOS-INFORMATION TECHNOLOGY",
				mainimg: process.env.PUBLIC_URL + '/assets/images/biofit.png',
				subimg1: process.env.PUBLIC_URL + '/assets/images/BIOFITd2.png',
				subimg2: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				subimg3: process.env.PUBLIC_URL + '/assets/images/BIOFITd3.png',
				tabletitle: "BIOFIT SPECIFICATION",
				infomation: "· 신체운동과 인지훈련을 동시에 실시할 수 있도록 제작된 인지훈련 장비\n · 지역사회 노인을 대상으로 신체운동과 인질훈련을 함께 실시하였을때 기억력, 추론력, 협응력 유의하게 향상\n · 터치, 적외선 센싱등을 통한 입력센서와 LED, 사운드, 진동을 통한 피드백\n · 평가모드를 통해 테스트시 연령에 따른 평균값과 비교 정보 제공 · 지속적으로 업데이트 되는 인지-신체훈련프로그램\n · 검사와 훈련 프로그램을 모두 제공하여 향상도에 대한 추적관리 가능\n · 고령자들 뿐만 아니라 인지 발달장애가 있는 아동 및 청소년 대상으로도 사용가\n · 다수가 함께 인지훈련을 할 수 있어 게임화(Gamification) 효과",
				spectable: {},
				softwaretable: {}
			},
		]

		return (
			<>
				<PC>
					<Box pt={15}
						sx={{ bgcolor: 'background.paper', display: 'flex', whiteSpace: "pre-wrap" }}
					>
						<Tabs
							orientation="vertical"
							variant="scrollable"
							value={value}
							onChange={this.handleChange}
							aria-label="Vertical tabs example"
							sx={{ width: "200px", borderRight: 1, borderColor: 'divider' }}
						>
							<Tab label="BIOS-mini" {...a11yProps(0)} />
							<Tab label="BIOS-s8" {...a11yProps(1)} />
							<Tab label="BIOS-s24" {...a11yProps(2)} />
							<Tab label="BIOS-s40" {...a11yProps(3)} />
							<Tab label="BIOS-ST" {...a11yProps(4)} />
							<Tab label="BIOS-micro" {...a11yProps(5)} />
							<Tab label="BIOS-S3" {...a11yProps(6)} />
							<Tab label="BIOS-WS8" {...a11yProps(7)} />
							<Tab label="BIOFIT" {...a11yProps(8)} />
						</Tabs>
						{
							paneldata.map((e, i) => 
							{
								// console.log(e.spectable.biosignalChannel);
								return (
									<TabPanel value={value} index={e.id} >
										<DetailContainerBox mx={10}>
											<DetailBox sx={{ width: "100vh" }}>
												<TopTypography>{e.title}</TopTypography>
												<Box display="flex">
													<SubTypography>{e.subtitle}</SubTypography><Typography sx={{ fontWeight: "700" }}>{e.subtitlestress}</Typography>
												</Box>
												<SubTypography>{e.subtitle2}</SubTypography>
											</DetailBox>
											<DetailBox sx={{ width: "20%" }}>
												<Box display="column">
													<DetailSubImgBox component="img" src={e.subimg1} alt="상품 이름" />
													<DetailSubImgBox component="img" src={e.subimg2} alt="상품 이름" />
													<DetailSubImgBox component="img" src={e.subimg3} alt="상품 이름" />
												</Box>
											</DetailBox>
											<DetailBox sx={{ width: "70%" }}>
												<ProductImgBox>
													<BoxZoomIn component="img" sx={{ width: "600px", height: "400px" }} src={e.mainimg} alt="상품 이름" />
													{/* @@ biofit 메인이미지사이즈 임시조절 */}
												</ProductImgBox>
											</DetailBox>
										</DetailContainerBox>
										<DetailBox sx={{ width: "100%" }}>
											<Box display="flex">
												<Box display="column">
													<Box mx={5} sx={{}}>
														<TableTypography my={2} >{e.tabletitle}</TableTypography>
													</Box>
													<Box>
														<Typography sx={{ whiteSpace: "pre-line" }} my={2} >{e.infomation}</Typography>
													</Box>

													<SpecTable
														biosignalChannel={e.spectable.biosignalChannel}
														biosignalMonopolarChannel={e.spectable.biosignalMonopolarChannel}
														biosignalBipolarChannel={e.spectable.biosignalBipolarChannel}
														samplingRate={e.spectable.samplingRate}
														noise={e.spectable.noise}
														adConversion={e.spectable.adConversion}
														inputImpedance={e.spectable.inputImpedance}
														leadOnOffChecking={e.spectable.leadOnOffChecking}
														powerSupply={e.spectable.powerSupply}
														communicationInterface={e.spectable.communicationInterface}
														software={e.spectable.software}
														size={e.spectable.size}
														weight={e.spectable.weight}
														channelNumber={e.spectable.channelNumber}
														resoluiton={e.spectable.resoluiton}
														filtering={e.spectable.filtering}
														dynamicRange={e.spectable.dynamicRange}
														motionSensors={e.spectable.motionSensors}
														connectivity={e.spectable.connectivity}
														outputFileFormat={e.spectable.outputFileFormat}
														dataTransformation={e.spectable.dataTransformation}
														battery={e.spectable.battery}
														operatingTime={e.spectable.operatingTime}
														eventLock={e.spectable.eventLock}
														eventJitter={e.spectable.eventJitter}
														supportedOS={e.spectable.supportedOS}
													/>
												</Box>
												<Box mx={5}>
													<TableTypography my={2}>{e.softwaretitle}</TableTypography>
													<SoftwareTable
														acquisition={e.softwaretable.acquisition}
														signalAnalysis={e.softwaretable.signalAnalysis}
														display={e.softwaretable.display}
														synchronizationWithOtherSW={e.softwaretable.synchronizationWithOtherSW}
													/>
												</Box>
											</Box>
										</DetailBox>
									</TabPanel>
								)
							})
						}
					</Box >
					<Footer />
				</PC>
				<TABLET>
					<Box pt={10} sx={{height:"100%", bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={this.handleChange}
							variant="scrollable"
							scrollButtons
							allowScrollButtonsMobile
							aria-label="scrollable force tabs example"
						>
							<Tab label="BIOS-mini" {...a11yProps(0)} />
							<Tab label="BIOS-s8" {...a11yProps(1)} />
							<Tab label="BIOS-s24" {...a11yProps(2)} />
							<Tab label="BIOS-s40" {...a11yProps(3)} />
							<Tab label="BIOS-ST" {...a11yProps(4)} />
							<Tab label="BIOS-micro" {...a11yProps(5)} />
							<Tab label="BIOS-S3" {...a11yProps(6)} />
							<Tab label="BIOS-WS8" {...a11yProps(7)} />
							<Tab label="BIOFIT" {...a11yProps(8)} />
						</Tabs>
						{
							paneldata.map((e, i) => 
							{
								// console.log(e.spectable.biosignalChannel);
								return (
									<TabPanel value={value} index={e.id} >
										<Box m={1}>
											<DetailBox sx={{}}>
												<MoTopTypography>{e.title}</MoTopTypography>
												<Box display="flex">
													<MoSubTypography>{e.subtitle}</MoSubTypography><Typography sx={{ fontWeight: "500" }}>{e.subtitlestress}</Typography>
												</Box>
												<MoSubTypography>{e.subtitle2}</MoSubTypography>
											</DetailBox>
											<MoDetailBox display="flex" sx={{ width: "100%" }}>
											<MoDetailBox mt={5} sx={{ width: "300px", height:"100%" }}>
													<Box display="flex" flexDirection="column">
														<MoDetailSubImgBox component="img" src={e.subimg1} alt="상품 이름" />
														<MoDetailSubImgBox component="img" src={e.subimg2} alt="상품 이름" />
														<MoDetailSubImgBox component="img" src={e.subimg3} alt="상품 이름" />
													</Box>
												</MoDetailBox>
												<MoProductImgBox>
													<BoxZoomIn component="img" sx={{ width: "300px", height:"200px" }} src={e.mainimg} alt="상품 이름" />
													{/* @@ biofit 메인이미지사이즈 임시조절 */}
												</MoProductImgBox>
											</MoDetailBox>
										</Box>
										<DetailBox display="flex " justifyContent="center" sx={{ width: "100%"}}>
											<Box justifyContent="column" height="100%">
												<Box display="column">
													<Box mx={5} sx={{}}>
														<TableTypography my={2} >{e.tabletitle}</TableTypography>
													</Box>
													<Box>
														<Typography sx={{ whiteSpace: "pre-line" }} my={2} >{e.infomation}</Typography>
													</Box>
													<SpecTable
														biosignalChannel={e.spectable.biosignalChannel}
														biosignalMonopolarChannel={e.spectable.biosignalMonopolarChannel}
														biosignalBipolarChannel={e.spectable.biosignalBipolarChannel}
														samplingRate={e.spectable.samplingRate}
														noise={e.spectable.noise}
														adConversion={e.spectable.adConversion}
														inputImpedance={e.spectable.inputImpedance}
														leadOnOffChecking={e.spectable.leadOnOffChecking}
														powerSupply={e.spectable.powerSupply}
														communicationInterface={e.spectable.communicationInterface}
														software={e.spectable.software}
														size={e.spectable.size}
														weight={e.spectable.weight}
														channelNumber={e.spectable.channelNumber}
														resoluiton={e.spectable.resoluiton}
														filtering={e.spectable.filtering}
														dynamicRange={e.spectable.dynamicRange}
														motionSensors={e.spectable.motionSensors}
														connectivity={e.spectable.connectivity}
														outputFileFormat={e.spectable.outputFileFormat}
														dataTransformation={e.spectable.dataTransformation}
														battery={e.spectable.battery}
														operatingTime={e.spectable.operatingTime}
														eventLock={e.spectable.eventLock}
														eventJitter={e.spectable.eventJitter}
														supportedOS={e.spectable.supportedOS}
													/>
												</Box>  
												<Box>
													<TableTypography paddingY={2}>{e.softwaretitle}</TableTypography>
													<SoftwareTable
														acquisition={e.softwaretable.acquisition}
														signalAnalysis={e.softwaretable.signalAnalysis}
														display={e.softwaretable.display}
														synchronizationWithOtherSW={e.softwaretable.synchronizationWithOtherSW}
													/>
												</Box>
											</Box>
										</DetailBox>
									</TabPanel>
								)
							})
						}
					</Box>
					<Footer />
				</TABLET>
				<MOBILE>
					<Box pt={15} sx={{ width: "100%", bgcolor: 'background.paper' }}>
						<Tabs
							value={value}
							onChange={this.handleChange}
							variant="scrollable"
							scrollButtons
							allowScrollButtonsMobile
							aria-label="scrollable force tabs example"
						>
							<Tab label="BIOS-mini" {...a11yProps(0)} />
							<Tab label="BIOS-s8" {...a11yProps(1)} />
							<Tab label="BIOS-s24" {...a11yProps(2)} />
							<Tab label="BIOS-s40" {...a11yProps(3)} />
							<Tab label="BIOS-ST" {...a11yProps(4)} />
							<Tab label="BIOS-micro" {...a11yProps(5)} />
							<Tab label="BIOS-S3" {...a11yProps(6)} />
							<Tab label="BIOS-WS8" {...a11yProps(7)} />
							<Tab label="BIOFIT" {...a11yProps(8)} />
						</Tabs>
						{
							paneldata.map((e, i) => 
							{
								// console.log(e.spectable.biosignalChannel);
								return (
									<TabPanel value={value} index={e.id} >
										<Box m={1}>
											<DetailBox sx={{ width: "100%" }}>
												<MoTopTypography>{e.title}</MoTopTypography>
												<Box display="flex">
													<MoSubTypography>{e.subtitle}</MoSubTypography><Typography sx={{ fontWeight: "500" }}>{e.subtitlestress}</Typography>
												</Box>
												<MoSubTypography>{e.subtitle2}</MoSubTypography>
											</DetailBox>
											<MoDetailBox sx={{ width: "100%" }}>
												<MoProductImgBox>
													<BoxZoomIn component="img" sx={{ width: "280px" }} src={e.mainimg} alt="상품 이름" />
													{/* @@ biofit 메인이미지사이즈 임시조절 */}
												</MoProductImgBox>
												<DetailBox mt={5} sx={{ width: "300px" }}>
													<Box display="flex">
														<MoDetailSubImgBox component="img" src={e.subimg1} alt="상품 이름" />
														<MoDetailSubImgBox component="img" src={e.subimg2} alt="상품 이름" />
														<MoDetailSubImgBox component="img" src={e.subimg3} alt="상품 이름" />
													</Box>
												</DetailBox>
											</MoDetailBox>
										</Box>
										<DetailBox sx={{ width: "100%" }}>
											<Box justifyContent="column">
												<Box display="column">
													<Box mx={5} sx={{}}>
														<TableTypography my={2} >{e.tabletitle}</TableTypography>
													</Box>
													<Box>
														<Typography sx={{ whiteSpace: "pre-line" }} my={2} >{e.infomation}</Typography>
													</Box>
													<SpecTable
														biosignalChannel={e.spectable.biosignalChannel}
														biosignalMonopolarChannel={e.spectable.biosignalMonopolarChannel}
														biosignalBipolarChannel={e.spectable.biosignalBipolarChannel}
														samplingRate={e.spectable.samplingRate}
														noise={e.spectable.noise}
														adConversion={e.spectable.adConversion}
														inputImpedance={e.spectable.inputImpedance}
														leadOnOffChecking={e.spectable.leadOnOffChecking}
														powerSupply={e.spectable.powerSupply}
														communicationInterface={e.spectable.communicationInterface}
														software={e.spectable.software}
														size={e.spectable.size}
														weight={e.spectable.weight}
														channelNumber={e.spectable.channelNumber}
														resoluiton={e.spectable.resoluiton}
														filtering={e.spectable.filtering}
														dynamicRange={e.spectable.dynamicRange}
														motionSensors={e.spectable.motionSensors}
														connectivity={e.spectable.connectivity}
														outputFileFormat={e.spectable.outputFileFormat}
														dataTransformation={e.spectable.dataTransformation}
														battery={e.spectable.battery}
														operatingTime={e.spectable.operatingTime}
														eventLock={e.spectable.eventLock}
														eventJitter={e.spectable.eventJitter}
														supportedOS={e.spectable.supportedOS}
													/>
												</Box>
												<Box>
													<TableTypography my={2}>{e.softwaretitle}</TableTypography>
													<SoftwareTable
														acquisition={e.softwaretable.acquisition}
														signalAnalysis={e.softwaretable.signalAnalysis}
														display={e.softwaretable.display}
														synchronizationWithOtherSW={e.softwaretable.synchronizationWithOtherSW}
													/>
												</Box>
											</Box>
										</DetailBox>
									</TabPanel>
								)
							})
						}
					</Box>
					<Footer />
				</MOBILE>
			</>
		);
	}
};

const ProductDetailsWrap = props =>
{
	const { state } = useLocation();
	const { selectedId } = state;
	console.log(selectedId);
	return <ProductDetails selectedId={selectedId} {...props} />
};

export default ProductDetailsWrap;
