import { Component } from "react";
import "../App.css";

import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default class HwPage extends Component
{
	// constructor(props)
	// {
	// 	super(props);
	// }

	componentDidMount()
	{
	}

	render()
	{
		// const { children } = this.props;
		// console.log( this.props);

		return (
			<Box sx={{ backgroundColor: "white", marginTop: 20 }}>
				<Container sx={{ backgroundColor: "white", display: "column", justifyContent: "center", width: "100vh", mb: 10 }}>
					<Box sx={{ display: "flex", justifyContent: "center", }}>
						<Typography variant="h4" m={5}
						>주요제품
						</Typography>
					</Box>
					<Box sx={{ display: "column", }}>
						<Box sx={{ display: "flex" }}>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image={process.env.PUBLIC_URL + "/assets/images/S3.png"}
									title="BIOS-S3"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										BIOS-S3
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 130 }}
									image={process.env.PUBLIC_URL + "/assets/images/S8.png"}
									title="BIOS-S8"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										BIOS-S8
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image={process.env.PUBLIC_URL + "/assets/images/S24.png"}
									title="BIOS-S24"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										BIOS-S24
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image={process.env.PUBLIC_URL + "/assets/images/S40.png"}
									title="BIOS-S40"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										BIOS-S40
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image={process.env.PUBLIC_URL + "/assets/images/mini.png"}
									title="BIOS-mini"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										Lizard
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
						</Box>
						<Box sx={{ display: "flex" }}>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image={process.env.PUBLIC_URL + "/assets/images/micro.png"}
									title="BIOS-micro"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										Lizard
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image="/static/images/cards/contemplative-reptile.jpg"
									title="green iguana"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										Lizard
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image="/static/images/cards/contemplative-reptile.jpg"
									title="green iguana"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										Lizard
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image="/static/images/cards/contemplative-reptile.jpg"
									title="green iguana"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										Lizard
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
							<Card sx={{ maxWidth: 345, m: 1 }}>
								<CardMedia
									sx={{ height: 140 }}
									image="/static/images/cards/contemplative-reptile.jpg"
									title="green iguana"
								/>
								<CardContent>
									<Typography gutterBottom variant="h5" component="div">
										Lizard
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Lizards are a widespread group of squamate reptiles,
									</Typography>
								</CardContent>
								<CardActions>
									<Button size="small">More</Button>
								</CardActions>
							</Card>
						</Box>
					</Box>
				</Container>
			</Box>
		);
	}
}