import React, { Component } from "react";
import { MOBILE, TABLET, PC } from "./global";
import "../App.css";

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';

const SlideImgBox = styled(Box)({
	filter: "brightness(70%)"
});

// const MoSlideImgBox = styled(Box)({
// 	filter: "brightness(85%)",
// 	position: "absolute",
// 	clip: "rect(100px, 0px, 0px, 500px)",
// });

const SlideTextBox = styled(Box)({
	position: "absolute",
	top: "70%",
	left: "5%",
	transform: "translate( 20%, 30%)",
	fontSize: "50px",
	// textAlign: "center"
});

const TaSlideTextBox = styled(Box)({
	position: "absolute",
	top: "50%",
	left: "5%",
	transform: "translate( 20%, 30%)",
	fontSize: "50px",
	// textAlign: "center"
});

const MobileSlideTextBox = styled(Box)({
	// position: "absolute",
	// top: "70%",
	margin: 10,
});

const SlideTitle = styled(Typography)({
	fontSize: "100px",
	fontWeight: "bold",
	color: "#ffffff",
	letterSpacing: ".1em",
});

const TaSlideTitle = styled(Typography)({
	fontSize: "50px",
	fontWeight: "bold",
	color: "#ffffff",
	letterSpacing: ".1em",
});


export default class MainSlider extends Component
{
	render()
	{
		const settings = {
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: false,
			autoplaySpeed: 2500,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerPadding: "0px",
			margin: "0"
		};

		const tasettings = {
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3000,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerPadding: "0px",
			margin: "0"
		};

		const mosettings = {
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerPadding: "0px",
			margin: "0"
		};

		return (
			<>
				<PC>
					<Box sx={{ height: "100vh", backgroundColor: "black" }}>
						<Slider {...settings} width="100%">
							<Grid position="relative">
								<SlideImgBox m={0} className="slide-img" component="img" sx={{ height: "100vh", width: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-19.jpg'}>
								</SlideImgBox>
								<SlideTextBox className="slide-text" display="flex" flexDirection="column">
									<SlideTitle>BioBrain</SlideTitle>
									<Box>
										<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }}>당신의 건강한 내일을 위해, 우리의 혁신적인 기술이 함께합니다.</Typography>
										<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }} >AI와 바이오의 만남으로, 사랑하는 사람들과 함께하는 모든 순간이 더욱 가치 있게.</Typography>
									</Box>
								</SlideTextBox>
							</Grid>
							<Grid position="relative">
								<SlideImgBox m={0} className="slide-img" component="img" sx={{ height: "100vh", width: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-23.jpg'}>
								</SlideImgBox>
								<SlideTextBox className="slide-text">
								<SlideTitle>BioBrain</SlideTitle>
									<Typography variant="body1" mr={20} sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오 기술과 정교한 데이터 분석은 의료 진단의 정확도를 극대화합니다. </Typography>
									<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }}>전문성을 바탕으로 개발된 우리의 솔루션은 의료 분야의 새로운 기준을 제시합니다.</Typography>
								</SlideTextBox>
							</Grid>
							<Grid position="relative">
								<SlideImgBox m={0} className="slide-img" component="img" sx={{ height: "100vh", width: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-20.jpg'}>
								</SlideImgBox>
								<SlideTextBox className="slide-text">
								<SlideTitle>BioBrain</SlideTitle>
									<Typography variant="body1" ml={1} sx={{ color: "#ffffff", fontWeight: "bold" }}>우리의 약속은 더 나은 미래와 건강한 사회를 위해 지속적인 연구와 혁신을 통해 모두의 삶에 따뜻한 변화를 가져오는 것입니다.</Typography>
									<Typography variant="body1" ml={1} sx={{ color: "#ffffff", fontWeight: "bold" }}>모든 이들이 더 건강하고 행복한 삶을 영위할 수 있도록 우리의 끊임없는 노력은 계속됩니다.</Typography>
								</SlideTextBox>
							</Grid>
						</Slider>
					</Box>
				</PC>
				<TABLET>
					<Box sx={{width: "100%", height: "100vh", backgroundColor: "black" }}>
						<Slider {...tasettings} width="100%">
							<Grid position="relative">
								<SlideImgBox className="slide-img" component="img" sx={{ height: "100vh", width: "100%" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-19.jpg'}>
								</SlideImgBox>
								<TaSlideTextBox mb={1} className="slide-text" display="flex" flexDirection="column">
								<TaSlideTitle>BioBrain</TaSlideTitle>
									<Box >
										<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }}>당신의 건강한 내일을 위해, 우리의 혁신적인 기술이 함께합니다.</Typography>
										<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }} >AI와 바이오의 만남으로, 사랑하는 사람들과 함께하는 모든 순간이 더욱 가치 있게.</Typography>
									</Box>
								</TaSlideTextBox>
							</Grid>
							<Grid position="relative">
								<SlideImgBox m={0} className="slide-img" component="img" sx={{ width: "100%", height: "100vh" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-23.jpg'}>
								</SlideImgBox>
								<TaSlideTextBox mb={5} className="slide-text">
								<TaSlideTitle>BioBrain</TaSlideTitle>
									<Typography variant="body1" mr={5} sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오 기술과 정교한 데이터 분석은 의료 진단의 정확도를 극대화합니다. </Typography>
									<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }}>전문성을 바탕으로 개발된 우리의 솔루션은 의료 분야의 새로운 기준을 제시합니다.</Typography>
								</TaSlideTextBox>
							</Grid>
							<Grid position="relative">
								<SlideImgBox m={0} className="slide-img" component="img" sx={{ width: "100%", height: "100vh" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-20.jpg'}>
								</SlideImgBox>
								<TaSlideTextBox mb={5} className="slide-text">
								<TaSlideTitle>BioBrain</TaSlideTitle>
									<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }}>더 나은 미래, 더 건강한 사회를 향한 우리의 약속. </Typography>
									<Typography variant="body1" sx={{ color: "#ffffff", fontWeight: "bold" }}> 지속적인 연구와 혁신이 어우러져 모든 이의 삶에 따뜻한 변화를 가져다줍니다.</Typography>
								</TaSlideTextBox>
							</Grid>
						</Slider>
					</Box>
				</TABLET>
				<MOBILE>
					<Box sx={{ height: "100vh", backgroundColor: "black" }}>
						<Slider {...mosettings} width="100%" height="100%">
							<Grid>
								<SlideImgBox mt={15} className="slide-img" component="img" sx={{ height: "280px", width: "420px", overflow: "hidden" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-19.jpg'}>
								</SlideImgBox>
								<MobileSlideTextBox className="slide-text">
								<TaSlideTitle>BioBrain</TaSlideTitle>
									<Typography variant="caption" sx={{ color: "#ffffff", fontWeight: "bold" }}>당신의 건강한 내일을 위해, 우리의 혁신적인 기술이 함께합니다.</Typography>
									<Typography variant="caption" sx={{ color: "#ffffff", fontWeight: "bold" }}>AI와 바이오의 만남으로, 사랑하는 사람들과 함께하는 모든 순간이 더욱 가치 있게.</Typography>
								</MobileSlideTextBox>
							</Grid>
							<Grid>
								<SlideImgBox mt={15} className="slide-img" component="img" sx={{ height: "280px", width: "380px", overflow: "hidden" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-23.jpg'}>
								</SlideImgBox>
								<MobileSlideTextBox className="slide-text">
								<TaSlideTitle>BioBrain</TaSlideTitle>
									<Typography variant="caption" sx={{ color: "#ffffff", fontWeight: "bold" }}>바이오 기술과 정교한 데이터 분석은 의료 진단의 정확도를 극대화합니다. </Typography>
									<Typography variant="caption" sx={{ color: "#ffffff", fontWeight: "bold" }}>전문성을 바탕으로 개발된 우리의 솔루션은 의료 분야의 새로운 기준을 제시합니다.</Typography>
								</MobileSlideTextBox>
							</Grid>
							<Grid>
								<SlideImgBox mt={15} className="slide-img" component="img" sx={{ height: "280px", width: "420px", overflow: "hidden" }} src={process.env.PUBLIC_URL + '/assets/images/photo_2024-01-19_15-54-20.jpg'}>
								</SlideImgBox>
								<MobileSlideTextBox className="slide-text">
								<TaSlideTitle>BioBrain</TaSlideTitle>
									<Typography variant="caption" sx={{ color: "#ffffff", fontWeight: "bold" }}>더 나은 미래, 더 건강한 사회를 향한 우리의 약속. </Typography>
									<Typography variant="caption" sx={{ color: "#ffffff", fontWeight: "bold" }}> 지속적인 연구와 혁신이 어우러져 모든 이의 삶에 따뜻한 변화를 가져다줍니다.</Typography>
								</MobileSlideTextBox>
							</Grid>
						</Slider>
					</Box>
				</MOBILE>
			</>
		);
	}
}