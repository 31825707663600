import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "../App.css";

import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";
import Footer from './footer';

const MobileTabBox = styled(Button)({
	width: "150px",
	height: "150px",
	margin: 5,
	backgroundColor: "black",
	color: "white",
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
});

// const MobileTypo = styled(Typography)({
// 	display: "flex",
// 	justifyContent: "center",
// 	alignItems: "center",
// 	fontWeight: "bold",
// 	textTransform: "none"
// });

const MenuSubButton = styled(Button)({
	width: "250px",
	height: "250px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "20px",
	color: 'white',
	border: "1px solid",
	borderImage: "linear-gradient(0deg, #8360c3 0%, #2ebf91 100%)",
	borderImageSlice: 1,
	transition: "transform 0.3s",
	'&:hover': {
		transform: "scale(1.1)",
		cursor: "pointer",
	},
	textTransform: "none",
	fontSize: 22, // 추가된 속성
	fontWeight: 600 // 추가된 속성
});

class BioScanPage extends Component
{
	constructor(props)
	{
		super(props);
		this.state = { isHovered: false };
	}

	componentDidMount()
	{
		AOS.init();
	}

	handleBackButtonClick = () =>
	{
		this.props.history.push('/bioscanpage'); // '/경로'는 BioScanPage로 이동할 경로
	}

	render()
	{
		const tabs = [
			{ title: "BioScan - HRV", url: "http://biobrain.kr/bioscan-hrv" },
			{ title: "BioScan - ST", url: "http://biobrain.kr/bioscan-st" },
			{ title: "BioScan - TopoMap", url: "http://biobrain.kr/bioscan-topomap" },
			{ title: "BioScan - Spectrum", url: "http://biobrain.kr/bioscan-spectrum" },
			{ title: "BioScan - Mobile", url: "https://biobrain.kr/bioscan-mobile/" },
			{ title: "BioScan - micro", url: "https://biobrain.kr/bioscan-micro/" },
		];

		return (
			<>
				<PC>
					<Box sx={{ width: "100%", height: "100vh", backgroundColor: "black", marginY: "100px" }} display="flex" justifyContent="center" alignItems="center" >
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexWrap="wrap"
							gap={1}
							data-aos="fade-up"
							data-aos-delay="500"
							data-aos-duration="900"
							paddingBottom={5}
						>
							{tabs.map((tab, index) => (
								<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
									<MenuSubButton>
										<Typography variant='h5' sx={{ textTransform: "none" }}>{tab.title}</Typography>
									</MenuSubButton>
								</Link>
							))}
						</Box>
					</Box >
					<Footer />
				</PC>
				<TABLET>
					<Box sx={{ width: "100%", height: "100%", backgroundColor: "black", paddingTop: "150px", marginBottom:"100px" }} >
						<Box height="100%" data-aos="fade-up" data-aos-delay="500" data-aos-duration="900" display="flex" justifyContent="center" alignItems="center">
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
								paddingBottom={5}
							>
								{tabs.map((tab, index) => (
									<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
										<MenuSubButton>
											<Typography variant='h6' sx={{ textTransform: "none" }}>{tab.title}</Typography>
										</MenuSubButton>
									</Link>
								))}
							</Box>
						</Box>
					</Box >
					<Footer />
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100%", height: "100vh", backgroundColor: "black" }} >
						<Box height="100%" data-aos="fade-up" data-aos-delay="500" data-aos-duration="900" display="flex" justifyContent="center" alignItems="center">
							<Box
								display="flex"
								justifyContent="center"
								alignItems="center"
								flexWrap="wrap"
								gap={1}
								data-aos="fade-up"
								data-aos-delay="500"
								data-aos-duration="900"
								paddingBottom={5}
							>
								{tabs.map((tab, index) => (
									<Link to={tab.url} target="_blank" key={index} style={{ textDecoration: 'none' }}>
										<MobileTabBox>
											<Typography variant='subtitle1' sx={{ textTransform: "none" }}>{tab.title}</Typography>
										</MobileTabBox>
									</Link>
								))}
							</Box>
						</Box>
					</Box >
					<Footer />
				</MOBILE >
			</>
		);
	}
};

export default BioScanPage;