import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { MOBILE, PC } from "./global";

const ResearchAccordion = styled(Accordion)({
	backgroundColor: "black",
	color: "white"
});

const AccordionTypography = styled(Typography)({
	fontSize: "18px",
	fontWeight: "600"
});

class TabPanel extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			expanded: false,
		};
	}

	render()
	{
		const { children, value, index, ...other } = this.props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`vertical-tabpanel-${index}`}
				aria-labelledby={`vertical-tab-${index}`}
				{...other}
			>
				{value === index && (
					<>
						<PC>
							<Box sx={{ px: 4, ml: 10, width: "100%" }}>
								<Typography>{children}</Typography>
							</Box>
						</PC>
						<MOBILE>
							<Box sx={{ paddingBottom: 5, mt: 2, width: "100%", height: "300px" }}>
								<Typography>{children}</Typography>
							</Box>
						</MOBILE>
					</>
				)}
			</div>
		);
	}
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index)
{
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export default class VerticalTabs extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			value: 0,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event, newValue)
	{
		this.setState({ value: newValue });
	}

	AccordionChange = (panel) => (event, isExpanded) =>
	{
		this.setState({ expanded: isExpanded ? panel : false });
	};

	render()
	{
		const { value, expanded } = this.state;

		return (
			<>
				<PC>
					<Box pt={10} sx={{ bgcolor: "black", display: "column", whiteSpace: "pre-line", backgroundColor: "black" }}>
						<Box data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="1000"
							sx={{ width: 1000, flexGrow: 1, bgcolor: "black", display: 'flex', height: 500 }}
						>
							<Tabs
								orientation="vertical"
								variant="scrollable"
								value={value}
								onChange={this.handleChange}
								aria-label="Vertical tabs example"
								sx={{ borderRight: 2, borderColor: 'divider' }}
							>
								<Tab sx={{ fontSize: "20px", fontWeight: "700", color: "white" }} label="특허" {...a11yProps(0)} />
								<Tab sx={{ fontSize: "20px", fontWeight: "700", color: "white" }} label="Patents" {...a11yProps(1)} />
								<Tab sx={{ fontSize: "20px", fontWeight: "700", color: "white" }} label="Software" {...a11yProps(2)} />
							</Tabs>
							<TabPanel value={value} index={0}>
								<ResearchAccordion expanded={expanded === 'panel1'} onChange={this.AccordionChange('panel1')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<AccordionTypography>제 10 - 1641581</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={1}>
												뇌병변 장애인을 위한 동영상 인지훈련 기반의 근육 전기감각자극 신경재활 시스템 및 방법</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel2'} onChange={this.AccordionChange('panel2')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>제 10 - 1847476</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												CSF 플랫폼 연계를 통한 작업자 중심의 증강현실용 생체신호기반 스마트 작업헬멧
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel3'} onChange={this.AccordionChange('panel3')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>제 10 - 1850419</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel4'} onChange={this.AccordionChange('panel4')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>제 10 - 1859021</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												생체신호와 비콘기술을 이용한 웨어러블 디바이스 기반의 아동 케어시스템
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
							</TabPanel>
							<TabPanel value={value} index={1}>
								<ResearchAccordion expanded={expanded === 'panel9'} onChange={this.AccordionChange('panel9')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<AccordionTypography>1015667880000, 국내등록, 2015.</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												하지기능 및 보행기능을 위한 뇌-컴퓨터 인터페이스를 기반한 기능적 전기자극치료기<br />(Brain computer interface based functional electrical stimulator)</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel10'} onChange={this.AccordionChange('panel10')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>1014854160000, 국내등록, 2015</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												신체 대칭 구조에서 비 마비측 근활성도를 이용한 편측 마비 부위의 기능적 전기자극치료 시스템 및 전기자극치료 제어 방법<br />(FUNCTIONAL ELECTRICAL STIMULATION(FES) SYSTEM FOR AFFECTED SIDE IN SYMMETRY STRUCTURE OF PHYSICAL USING<br /> MUSCLE ACTIVITY OF NON-AFFECTED SIDE AND FES CONTROL METHOD THEREOF)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel11'} onChange={this.AccordionChange('panel11')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>1016415810000, 국내등록, 2016</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												뇌병변 장애인을 위한 동영상 인지훈련 기반의 근육 전기감각자극 신경재활 시스템 및 방법<br />(ELECTRICAL STIMULATION SYSTEM AND METHOD BASED ON MOVING IMAGE COGNITIVE TRAINING FOR ENCEPHALOPATHY)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel12'} onChange={this.AccordionChange('panel12')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>1017546960000, 국내등록, 2017</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												뇌기능 평가 장치 및 그 제어 방법<br />(APPARATUS FOR ESTIMATING BRAIN FUNCTION ANND METHOD FOR CONTROLLING THE SAME)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel13'} onChange={this.AccordionChange('panel13')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>국내등록, 2018</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												CSF 플랫폼 연계를 통한 작업자 중심의 증강현실용 생체신호기반 스마트 작업헬멧<br />(SMART HELMET BASED ON BIO-SIGNALS FOR AUGMENTED REALITY OF WORKER CENTRIC CONNECTION WITH CSF PLATFORM)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel14'} onChange={this.AccordionChange('panel14')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>국내등록, 2018</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템<br />(ELECTRONICALLY CONTROLLED PELTIER ALLEVIATE FEVER PATCH SYSTEM BASED ON MEASUREMENT OF SURFACE BODY TEMPERATURE)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel15'} onChange={this.AccordionChange('panel15')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>국내등록, 2018</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템<br />(ELECTRONICALLY CONTROLLED PELTIER ALLEVIATE FEVER PATCH SYSTEM BASED ON MEASUREMENT OF SURFACE BODY TEMPERATURE)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel16'} onChange={this.AccordionChange('panel16')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>국내등록, 2018</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템<br />(ELECTRONICALLY CONTROLLED PELTIER ALLEVIATE FEVER PATCH SYSTEM BASED ON MEASUREMENT OF SURFACE BODY TEMPERATURE)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel17'} onChange={this.AccordionChange('panel17')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>국내등록, 2018</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템<br />(ELECTRONICALLY CONTROLLED PELTIER ALLEVIATE FEVER PATCH SYSTEM BASED ON MEASUREMENT OF SURFACE BODY TEMPERATURE)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel18'} onChange={this.AccordionChange('panel18')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>국내등록, 2018</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템<br />(ELECTRONICALLY CONTROLLED PELTIER ALLEVIATE FEVER PATCH SYSTEM BASED ON MEASUREMENT OF SURFACE BODY TEMPERATURE)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
							</TabPanel>
							<TabPanel value={value} index={2}>
								<ResearchAccordion expanded={expanded === 'panel19'} onChange={this.AccordionChange('panel19')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<AccordionTypography>C - 2013 - 001692</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												심전도(ECG)기반 원자력 종사자 스트레스 측정 프로그램, 개발자, 저작등록, 2012 </Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel20'} onChange={this.AccordionChange('panel20')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>C - 2012 - 025763</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												CSF 플랫폼 연계를 통한 작업자 중심의 증강현실용 생체신호기반 스마트 작업헬멧
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel21'} onChange={this.AccordionChange('panel21')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>C - 2014 - 000548</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												체표온 측정기반의 전자제어식 펠티어 해열패치 시스템
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel22'} onChange={this.AccordionChange('panel22')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>C - 2017 - 010785</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												생체신호와 비콘기술을 이용한 웨어러블 디바이스 기반의 아동 케어시스템
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
								<ResearchAccordion expanded={expanded === 'panel23'} onChange={this.AccordionChange('panel23')}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<AccordionTypography>C - 2018 - 011849</AccordionTypography>
									</AccordionSummary>
									<AccordionDetails>
										<Box sx={{ borderTop: "3px solid #69c5d8 " }}>
											<Typography m={2}>
												Cloud Software for Acquisition and Analysis of Neurophysiological Biosignal based on IoT Biosensor<br /> (사물인터넷 생체센서기반 신경생리신호 수집분석용 클라우드 소프트웨어)
											</Typography>
										</Box>
									</AccordionDetails>
								</ResearchAccordion>
							</TabPanel>
						</Box>
					</Box>
				</PC>
			</>
		);
	}
};
