import React, { Component } from "react";

import "./swiperbutton.css";

export class SwiperButton extends Component
{
    constructor(props)
    {
        super(props);

        this.state = { mouseEnter: false };
    }

    onMouseEnter = (event) =>
    {
        this.setState({ mouseEnter: true });
    };

    onMouseLeave = (event) =>
    {
        this.setState({ mouseEnter: false });
    };

    onClick = (event) =>
    {
        const { onClick } = this.props;
        onClick();
    };

    render()
    {
        const { children, active, dot } = this.props;
        const { mouseEnter } = this.state;

        return (
            <button
                className={active ? "swiper_button swiper_button_active" : "swiper_button"}
                style={{ color: mouseEnter ? "#f3cf98" : "white" }}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={this.onClick}
            >
                {dot ? `${children}  ●` : children}
            </button>
        );
    }
};
